

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 62.5%;
}

body {
    color: #333;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.425;
    margin: 0
}

*,
:before,
:after {
    -moz-box-sizing: inherit;
    box-sizing: inherit
}

a,
a:hover,
a:focus {
    text-decoration: none
}

.site-inner {
    width: 100%;
    clear: both;
    padding: 90px 0;
    text-align: center;
}

.wrap {
    margin: 0 auto;
    max-width: 1170px;
}

.wrap:after {
    clear: both;
    content: " ";
    display: table;
}

h1 {
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 10px;
    font-family: museosans900;
    letter-spacing: .25px;
    color: #655143;
    font-size: 63px;
}

h2 {
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 10px;
    letter-spacing: .25px;
    color: #655143;
    font-family: museosans900;
    font-size: 25px;
}

p {
    margin: 0 0 28px;
    padding: 0;
    letter-spacing: .25px;
    font-family: museosans500;
    font-size: 18px;
}

.button-wrap {
    text-align: center;
}

.button-wrap a {
    color: #fff;
}

a.btn-large-blue {
    display: inline-block;
    border: 0;
    color: #fff;
    cursor: pointer;
    width: auto;
    border-radius: 15px / 15px;
    font-family: museosans900;
    font-size: 17px;
    padding: 12px 62px;
    background-color: #0391d6;
}

.header-image .site-title>a {
    background: url(../../assets/images/logo.png) no-repeat left;
    background-size: contain;
}

@media screen and (max-width: 960px) and (min-width: 661px) {
    h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 660px) {
    h1 {
        font-size: 38px;
    }
}

.footer-bottom {
    padding-top: 13px;
}