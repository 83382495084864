.img-z9 img {
	position: relative;
	z-index: 9;
}

.banner-cloudvps_bee img {
    max-width: 600px;
}
.row-vpstables_new .fl-row-content-wrap {
    margin-top: -200px !important;
}
.banner-cloudvps_captain .fl-row-content {
    position: relative;
    padding-top: 60px;
}
.banner-cloudvps_captain__row1 {
    position: absolute;
    right: 0;
    top: 45px;
    z-index: 1;
    max-width: 716px;
}
.fl-builder-edit .banner-cloudvps_captain__row1 {
    position: static;
    top: auto;
    z-index: 998;
}
@media (max-width: 991px) {
    .banner-cloudvps_captain .fl-row-content {
        padding-top: 0;
    }
    .banner-cloudvps_captain__row1 {
        position: static;
        right: auto;
        top: auto;
    }
    .banner-cloudvps_captain__row1 .fl-html {
        padding: 20px;
    }
}
@media (max-width: 680px) {
    .banner-cloudvps_captain__row1 h1 {font-size: 30px;}
    .banner-cloudvps_captain__row1 h2 {font-size: 25px;}
}
.utility-bar-left, .utility-bar-right {
    width: 50%;
}
.utility-bar-right .widget_text {
    display: inline-block;
}
.utility-bar-bottom {
    width: 100%;
}
/* Language selector */

.wpml-ls-legacy-dropdown .wpml-ls-sub-menu, .wpml-ls-legacy-dropdown a, .wpml-ls-legacy-dropdown .wpml-ls-item {
    padding: unset;
}
.wpml-ls-legacy-dropdown ul {
    padding: 13px 15px !important;
}
.wpml-ls-legacy-dropdown ul:hover {
    background: #00a4df;
}
.wpml-ls-legacy-dropdown ul:hover span {
    color: #fff;
}
.widget_icl_lang_sel_widget {
    display: inline-block;
    vertical-align: middle;
}
.wpml-ls-legacy-dropdown {
    width: unset;
}
.wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
    background: #00a4df;
    top: 31px;
}
.wpml-ls-sub-menu span {
    color: #ffefae;
}
.wpml-ls-legacy-dropdown a {
    color: #00a4df;
    border: 0;
    background-color: transparent;
    line-height: 1;
}
.wpml-ls-legacy-dropdown a.wpml-ls-item-toggle {
    padding-right: 5px;
}
.wpml-ls-legacy-dropdown a.wpml-ls-item-toggle:after {
    top: 6px;
    right: 0px;
}
.wpml-ls-link {
    text-align: left;
}
.wpml-ls-legacy-dropdown a:hover, .wpml-ls-legacy-dropdown a:focus, .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a {
    text-decoration: none;
    background: transparent;
    color: #fff;
}
.wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
    border-top: 0;
}
.wpml-ls-legacy-dropdown .wpml-ls-sub-menu a {
    padding-top: 4px;
}
/* New search */

.utility-bar {
    min-height: 45px;
}
.utility-bar-left, .utility-bar-right {
    padding: 0px 0;
}
.utility-bar-left .widget_text, .utility-bar-right .widget_text {
    padding-top: 12px;
}
.utility-bar-right aside {
    display: inline-block;
}
.utility-bar-right .widget-area, .utility-bar-left .widget-area {
    vertical-align: top;
}
.utility-bar .widget_icl_lang_sel_widget {
    float: right;
    margin-left: 11px;
}
.utility-bar .widget_icl_lang_sel_widget:hover .wpml-ls-legacy-dropdown a.wpml-ls-item-toggle:after {
    color: #fff;
}
.icl_lang_sel_translated {
    display: none;
}
.search-button {
    background: #F69231;
    display: inline-block;
    position: relative;
    padding: 10px 12px;
    z-index: 99;
}
.search-button i {
    color: #fff;
    font-size: 22px;
}
.search-button form {
    background: #F69231;
    padding: 6px 0px 7px;
    vertical-align: bottom;
    position: absolute;
    bottom: 0;
}
.search-button .search-form .search-field {
    width: 84%;
}
.search-button .search-form_new input[type="submit"] {
    background-color: #F69231;
    border-radius: 0;
    padding: 7.5px 22px;
    position: relative;
    top: 2.5px;
    left: -2px;
    background-image: url(assets/images/search_box_icon.png);
    background-size: 17px 24px;
    background-repeat: no-repeat;
    background-position: center center;
}
.search-wrap {
    display: none;
    position: absolute;
    top: 0;
    width: 345px;
    right: 0;
    height: 90px;
}
.search-button:hover .search-wrap, .force-showing {
    display: block;
}
.force-showing-2 {
    display: block;
}
.search-form_new {
    max-width: 430px;
    width: 100%;
}
.search-form_new input[type="search"] {
    border-radius: 7px;
    margin: 0;
    border: 1px solid #eee;
    background: #fff;
    font-weight: normal;
    font-family: 'museosans500';
}
@media (max-width: 520px) {
    .search-form_new {
        max-width: unset;
    }
    .utility-bar-bottom {
        display: block;
    }
    .utility-bar-left, .utility-bar-right {
        width: 100%;
        float: none;
    }
    .utility-bar {
        min-height: 89px;
    }
    #text-5 {
        display: block;
        position: absolute;
        top: 9px;
        padding-top: 0;
        right: 8px;
    }
    .utility-bar .wrap {
        padding-left: 0;
        padding-right: 0;
    }
    .search-button {
        left: 0;
        position: absolute;
        width: calc(100% - 60px);
    }
    .search-wrap {
        display: block;
        top: -39px;
        left: 0;
        height: 85px;
        width: 100%;
    }
    .search-button .search-form .search-field {
        vertical-align: top;
        margin-top: 1px;
    }
    .utility-bar-left .widget_text {
        padding-top: 5px;
        padding-bottom: 12px;
    }
    .utility-bar-left span:nth-of-type(3) {
        margin-left: 0px !important;
        margin-top: 4px !important;
    }
    .utility-bar-left .textwidget span {
        display: block;
        width: 100% !important;
        text-align: left !important;
        padding-left: 10px;
        font-size: 16px !important;
    }
    .utility-bar-left span:nth-of-type(1) {
        display: none;
    }
    .utility-bar-left span:nth-of-type(3) {
        margin-left: 200px;
    }
    .utility-bar-left span:nth-of-type(3) {
        margin-left: 0px !important;
    }
    .search-button form {
        padding: 4px 0px 5px;
        height: 48px;
    }
    .wpml-ls-legacy-dropdown ul {
        padding: 14px 12px 15px !important;
        height: 48px;
    }
    .utility-bar-right .widget_icl_lang_sel_widget {
        position: relative;
        top: -2px;
        vertical-align: bottom;
    }
    .wpml-ls-legacy-dropdown ul {
        background-color: #00a4df;
    }
    .wpml-ls-legacy-dropdown a {
        color: #fff;
    }
    .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
        background: #00a4df;
        top: 31px;
        left: 1px;
    }
}
@media (max-width: 372px) {
    .search-button .search-form .search-field {
        width: 75%;
    }
}
.ui-autocomplete {
    background: white;
    padding: 10px;
    max-width: unset;
    position: absolute;
    z-index: 9999;
}
.ui-state-focus {
    color: blue;
}
.ui-autocomplete li {
    cursor: pointer;
    padding-bottom: 5px;
}
.ui-autocomplete li:hover {
    background: #fefefe;
    color: blue;
}
.ui-autocomplete .featherlight.search h1 {
    font-size: 36px;
}
.featherlight.search {
    cursor: default;
}
.featherlight.search .featherlight-content {
    overflow: auto;
}
.featherlight.search .featherlight-content {
    background: #fff;
    padding: 30px;
}
.featherlight-loading.search .featherlight-content {
    background: transparent;
}
.featherlight-loading.search .featherlight-content {
    border: 8px solid #fefefe;
    border-left-color: #f19519;
}
.featherlight.search h1 {
    font-size: 22px;
}
.featherlight.search .item p {
    color: #333;
}
.featherlight.search .item p, .featherlight.search .item a {
    font-size: 16px;
    margin: 0 0 0;
    font-family: museosans500;
}
.featherlight.search h3 {
    margin-bottom: 4px;
}
.featherlight.search h3 {
    color: #f3a01a;
    font-size: 18px !important;
}
.featherlight.search .item {
    margin-bottom: 0;
    padding: 22px 0;
    border-bottom: 2px solid #ebebeb;
}
@media screen and (min-width: 980px) {
    .featherlight.search .featherlight-content {
        max-width: 980px;
    }
}
.beneath-bottom .one-third {
    width: 38%;
}
.one-third.second {
    width: 18%;
}
@media (max-width: 1050px) {
    .beneath-bottom .one-third {
        width: 100%;
    }
}
.bitcoin-logo,
.bitcoin-logo>img {
    max-width: 150px;
    padding: 35px 0;
}
html[lang="nl-NL"] .beneath-bottom #mc4wp_email {
    max-width: 224px;
}
@media (max-width: 600px) {
    html[lang="nl-NL"] .beneath-bottom #mc4wp_email {
        max-width: 189px;
    }
    .featherlight.search .featherlight-content {
        max-height: 93%;
        max-width: 100%;
        vertical-align: top;
        margin-top: 67px;
    }
    .featherlight-loading.search .featherlight-content {
        vertical-align: middle;
    }
    .featherlight.search .featherlight-close-icon {
        height: 22px;
        right: 18px;
        top: 24px;
        width: 22px;
        z-index: 9999;
    }
}
.banner-managed2 {
    background: #3297dd;
    /* Old browsers */
    background: -moz-linear-gradient(top, #3297dd 0%, #b7dff9 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #3297dd 0%, #b7dff9 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #3297dd 0%, #b7dff9 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3297dd', endColorstr='#b7dff9', GradientType=0);
    /* IE6-9 */
    position: relative;
}
.banner-managed2 h3 {
    color: #fff;
}
.banner-managed2 .fl-module-photo .fl-module-content {
    margin: 0;
}
.banner-managed2 .fl-module-content {
    position: relative;
    z-index: 2;
}
.banner-managed2:after {
    content: "";
    background: url(assets/images/banner-managedhosting-water.png) top;
    width: 100%;
    bottom: 0;
    position: absolute;
    height: 166px;
    z-index: 1;
}
@media (min-width: 1321px) {
    .banner-managed2 .fl-row-content, .banner-managed2 .fl-col-group, .banner-managed2 .fl-col, .banner-managed2 .fl-col-content {
        min-height: 520px;
    }
}
.banner-managed2 .fl-col-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-managed2 .fl-col:first-child .fl-col-content {
    align-items: left;
}
.banner-managed2 .fl-col:last-child .fl-module {
    margin-top: auto;
}
@media (max-width: 1320px) {
    .banner-managed2 .fl-col {
        width: 100%;
    }
    .banner-managed2 .fl-col:first-child .fl-col-content {
        padding: 20px 0;
    }
    .banner-managed2 .fl-photo-img-png img {
        max-width: 700px;
    }
}
.fl-rich-text ol {
    margin: 0px;
    padding-left: 15px;
}
.fl-rich-text ol li {
    margin: 0px;
    padding: 0px;
    text-indent: -1em;
    margin-left: 1em;
}
.fl-rich-text ul {
    margin: 0;
    padding-left: 3.15em;
}
.fl-rich-text ul li {
    margin: 0px;
    padding: 0px;
/*    text-indent: .32em;*/
    margin-left: -1.2em;
}
.fl-module-content ul li:before {
    padding-right: 0;
    left: -.5em;
}
.separator-cta {
    padding: 10px 0 5px;
}
.separator-cta h3 {
    font-size: 30px;
}
@media (max-width: 1215px) {
    .separator-cta {
        padding: 10px 15px 5px;
    }
}
@media (max-width: 800px) {
    .separator-cta h3 {
        text-align: center !important;
        margin-bottom: 15px;
        font-size: 26px;
    }
    .separator-cta {
        padding: 20px 15px 5px;
    }
}
/*Christmas 2017*/

.box-xmas-woods {
    background: url(https://www.snel.com/wp-content/uploads/xmas-discount-2017.png);
    background-repeat: no-repeat;
    height: 364px;
    background-position-x: center;
}
.bee-xmas-gift {
    background: url(https://www.snel.com/wp-content/uploads/xmas-bee-2017-1.png);
    background-repeat: no-repeat;
    height: 453px;
    background-position-x: center;
    margin-top: 37px;
}
.fl-photo-img.wp-image-63404.size-full {
    padding-top: 32px;
}
.box-xmas-banners {
    text-align: center;
}
.box-xmas-gift {
    background: url(https://www.snel.com/wp-content/uploads/xmas-gifts-2017.png);
    background-repeat: no-repeat;
    height: 364px;
    margin-top: -134px;
    background-position-x: center;
}
.banner.xmass h2 {
    color: #fff;
    margin-bottom: 13px;
    font-size: 38px;
    line-height: 1.4;
    margin-top: -68px;
}
.banner.xmass h1 {
    font-family: MuseoSans700;
    color: #fff;
    font-size: 38px;
    font-weight: 800;
    line-height: 1.1;
    margin-top: 30px;
}
.banner.xmass .fl-row-content-wrap {
    background-image: url(https://keycdn.snel.com/wp-content/uploads/xmas-background-2017.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    height: 489px;
    overflow: hidden;
}
.xmas-bee.wp-image-63404 {
    display: inline;
    height: auto;
    max-width: 100%;
}
@media (max-width: 1160px) {
    .banner.xmass h1 {
        font-family: MuseoSans700;
        color: #fff;
        font-size: 38px;
        font-weight: 800;
        line-height: 1.1;
        margin-top: 20px;
    }
    .box-xmas-woods {
        height: 373px;
        padding-top: 30px;
    }
    .banner.xmass h2 {
        color: #fff;
        margin-bottom: 13px;
        font-size: 38px;
        line-height: 1.4;
        margin-top: -100px;
    }
}
@media (max-width: 800px) {
    .banner.xmass .fl-row-content-wrap {
        height: auto;
    }
}
@media (max-width: 425px) {
    .banner.xmass h2 {
        color: #fff;
        margin-bottom: 13px;
        font-size: 32px;
        line-height: 1.4;
        margin-top: -38px;
    }
    .banner.xmass h1 {
        font-family: MuseoSans700;
        color: #fff;
        font-size: 32px;
        font-weight: 800;
        line-height: 1.1;
        margin-top: 30px;
    }
}
/**/

@media (max-width: 960px) {
    .mini-header .alignleft {
        position: absolute;
        padding-left: 1%;
        z-index: 1;
    }
    .title-area {
        z-index: 10;
        position: relative;
    }
}
.header-image .site-title>a {
    z-index: 12;
    position: relative;
}
.separator.red {
    background: #ea0202;
}
[class^="sprite-"], [class*=" sprite-"] {
    display: block;
    margin: auto;
}
.sprite-enterprise {
    background: url("assets/images/managed-icons.png") no-repeat -5px -14px;
    width: 126px;
    height: 126px;
}
.sprite-responsetime {
    background: url("assets/images/managed-icons.png") no-repeat -137px -9px;
    width: 126px;
    height: 126px;
}
.sprite-247 {
    background: url("assets/images/managed-icons.png") no-repeat -271px -5px;
    width: 126px;
    height: 126px;
}
.sprite-time {
    background: url("assets/images/managed-icons.png") no-repeat -401px -3px;
    width: 126px;
    height: 126px;
}
.sprite-os {
    background: url("assets/images/managed-icons.png") no-repeat -7px -143px;
    width: 126px;
    height: 126px;
}
.sprite-storage {
    background: url("assets/images/managed-icons.png") no-repeat -139px -143px;
    width: 126px;
    height: 126px;
}
.sprite-webserver {
    background: url("assets/images/managed-icons.png") no-repeat -273px -139px;
    width: 126px;
    height: 126px;
}
.sprite-controlpanel {
    background: url("assets/images/managed-icons.png") no-repeat -403px -136px;
    width: 126px;
    height: 127px;
}
.sprite-management {
    background: url("assets/images/managed-icons.png") no-repeat -533px -4px;
    width: 152px;
    height: 152px;
}
.sprite-monitoring {
    background: url("assets/images/managed-icons.png") no-repeat -530px -161px;
    width: 152px;
    height: 152px;
}
.banner-managed {
    min-height: auto;
}
.banner-managed .fl-row-content-wrap {
    background-image: url("assets/images/banner-managed.png");
    background-size: cover;
    background-position: center bottom;
}
.banner-managed .fl-photo {
    padding-top: 160px;
}
@media (min-width: 1161px) {
    .banner-managed .fl-rich-text {
        padding-top: 30px;
    }
}
@media (max-width: 1160px) {
    .banner-managed .fl-photo {
        padding-top: 60px;
    }
    .banner-managed .fl-col-group {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
    }
    .banner-managed .fl-col:first-child {
        -webkit-flex-basis: 100%;
        -ms-flex: 0 1 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 3 !important;
        -webkit-order: 2 !important;
        -ms-flex-order: 2 !important;
        order: 2 !important;
    }
    .banner-managed .fl-col:nth-child(2) {
        padding: 15px;
        -webkit-flex-basis: 100%;
        -ms-flex: 0 1 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 2 !important;
        -webkit-order: 1 !important;
        -ms-flex-order: 1 !important;
        order: 1 !important;
    }
}
.row-managed-icons [class^="sprite-"], .row-managed-icons [class*=" sprite-"] {
    margin-bottom: 19px;
}
.row-managed-blue {
    color: #fff;
}
.row-managed-monitoring .fl-rich-text, .row-managed-monitoring .fl-html {
    position: relative;
    z-index: 3;
}
.row-managed-monitoring .fl-row-content-wrap:after {
    background: inherit;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
    bottom: -40px;
    -webkit-backface-visibility: hidden;
    transform: skewY(1.5deg);
    transform-origin: 100% 0;
}
.row-managed-management .fl-row-content-wrap:after {
    background: inherit;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden;
    bottom: 0;
    transform: skewY(-1.5deg);
    transform-origin: 100%;
}
/* Managed Hosting Table */

.rwd-table {
    margin: 1em 0;
    min-width: 300px;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
}
.rwd-table .button {
    display: block;
    max-width: 120px;
    margin: auto;
}
.rwd-table .btn-medium a {
    padding: 15px 5px;
    line-height: 2.6;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'museosans700';
}
.rwd-table th {
    display: none;
}
.rwd-table td {
    display: block;
    border: 0;
    position: relative;
}
.rwd-table td:first-child {
    padding-top: 0.5em;
}
.rwd-table td:last-child {
    padding-bottom: 0.5em;
}
.rwd-table [data-th="Basic"]:before, .rwd-table [data-th="Premium"]:before, .rwd-table [data-th="Enterprise"]:before {
    content: attr(data-th);
    font-weight: bold;
    width: 6.5em;
    display: inline-block;
}
@media (min-width: 480px) {
    .rwd-table [data-th="Basic"]:before, .rwd-table [data-th="Premium"]:before, .rwd-table [data-th="Enterprise"]:before {
        display: none;
    }
}
.rwd-table th, .rwd-table td {
    text-align: left;
}
@media (min-width: 480px) {
    .rwd-table th, .rwd-table td {
        display: table-cell;
        padding: 0.25em 0.5em;
    }
    .rwd-table th:first-child, .rwd-table td:first-child {
        padding-left: 0;
    }
    .rwd-table th:last-child, .rwd-table td:last-child {
        padding-right: 0;
    }
}
.rwd-table {
    color: #042036;
    border-radius: 0.4em;
    border-width: 0;
    overflow: visible;
    max-width: 1205px;
    margin: auto;
}
.rwd-table tr:nth-child(even) {
    background: #cfd8d7;
}
.rwd-table tr:nth-child(odd) {
    background: #e8f0ef;
}
.rwd-table th:not(:first-of-type) {
    text-align: center;
}
.rwd-table .fa {
    font-size: 26px;
    background: white;
    border-radius: 100%;
    padding: 0;
    width: 21px;
    height: 17px;
    line-height: 16px;
}
.rwd-table .fa-times-circle {
    color: red;
}
.rwd-table .fa-check-circle {
    color: green;
}
@media (min-width: 480px) {
    .rwd-table td:first-of-type {
        max-width: 200px;
    }
    .rwd-table [data-th="Basic"], .rwd-table [data-th="Premium"], .rwd-table [data-th="Enterprise"] {
        width: 19%;
        text-align: center;
    }
    .rwd-table th {
        position: relative;
        overflow: visible;
    }
    .rwd-table th img {
        display: block;
        margin: auto;
        position: absolute;
        right: 0;
        left: 0;
        top: -70px;
    }
}
@media (max-width: 480px) {
    .rwd-table .fa {
        margin-left: 25%;
    }
    .rwd-table th img {
        display: none;
    }
}
.rwd-table th, .rwd-table td {
    margin: 0.5em 1em;
}
.rwd-table td {
    font-size: 18px;
    font-family: museosans500;
}
@media (min-width: 480px) {
    .rwd-table th, .rwd-table td {
        padding: 1.15em 1em !important;
        line-height: 1;
    }
}
@media (min-width: 480px) {
    .rwd-table th {
        padding: 2.5em 1em 1.5em !important;
    }
}
.rwd-table th, .rwd-table td:before {
    color: #042036;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 1px;
    font-family: museosans700;
}
.rwd-table th, .rwd-table tfoot tr {
    background: #bccfcd !important;
    font-weight: bold;
}
.rwd-table tfoot [data-th="Basic"], .rwd-table tfoot [data-th="Premium"], .rwd-table tfoot [data-th="Enterprise"] {
    color: green;
    font-size: 22px;
}
@media (min-width: 480px) {
    .rwd-table tfoot td:not(:first-of-type) {
        text-align: center;
    }
    .rwd-table tfoot [data-th="Basic"], .rwd-table tfoot [data-th="Premium"], .rwd-table tfoot [data-th="Enterprise"] {
        padding: 32px 0 74px !important;
    }
    .rwd-table .button {
        position: absolute;
        min-width: 120px;
        left: 0;
        right: 0;
        bottom: 23px;
    }
    .rwd-table .has-added-charge [data-th="Basic"], .rwd-table .has-added-charge [data-th="Premium"], .rwd-table .has-added-charge [data-th="Enterprise"] {
        padding: 1.8em 1em !important;
    }
    .rwd-table .added-charge {
        position: absolute;
        font-size: 14px;
        left: 0;
        right: 0;
        bottom: 9px;
    }
}
/**/

.gscb_a {
    font: 34px/26px arial, sans-serif !important;
    padding-top: 0px;
}
.wgs_wrapper .gsc-input-box .gsib_a input {
    height: 19px !important;
    top: -3px;
}
.banner-blackfriday2 .fl-row-content-wrap {
    min-height: 540px;
    background-position: left bottom;
}
.blackfriday2-monday img {
    max-width: 372px;
}
.banner-blackfriday2 h1 {
    color: #F9B233;
    text-transform: uppercase;
    font-size: 40px;
    font-family: museosans900;
    letter-spacing: 2px;
    margin-bottom: 0;
    text-shadow: 1px 2px 0px #e7ca26;
}
.banner-blackfriday2 .fl-node-5a0cf488cf740 {
    text-align: center;
}
@media (max-width: 1199px) {
    .banner-blackfriday2 .fl-row-content-wrap {
        background-position: -254px bottom;
    }
}
@media (max-width: 990px) {
    .fl-node-5a0cf488cf740 {
        width: 100%;
        padding: 0px 11%;
    }
}
@media (min-width: 700px) {
    .banner-blackfriday2 h1 {
        font-size: 75px;
    }
}
.gsc-search-button {
    position: relative;
    right: 10px;
}
.banner-kitchen {
    text-align: right;
}
.banner-kitchen h1 {
    font-size: 40px;
}
.banner-kitchen_experience {
    text-align: center;
    margin-top: auto;
    bottom: 4px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    right: 7px;
    left: 0px;
}
.banner-kitchen_experience span {
    font-size: 28px;
    margin-bottom: 0;
    color: #ffefae;
    font-family: museosans700;
    letter-spacing: 2px;
}
.banner-kitchen_experience p {
    margin-bottom: 0;
}
.banner-kitchen_maincol {
    padding-top: 74px;
}
.banner-kitchen_main-text h3 {
    max-width: 50%;
    float: right;
}
@media (min-width: 1400px) {
    .banner-kitchen_maincol {
        padding-top: 68px;
        position: relative;
        left: 20px;
    }
    .banner-kitchen .fl-row-content {
        min-height: 440px;
    }
}
@media (max-width: 1200px) {
    .banner-kitchen_maincol {
        padding-right: 20px;
    }
}
@media (max-width: 1000px) {
    .banner-kitchen_maincol {
        padding-right: 20px;
    }
    .banner-kitchen_main-text h3 {
        max-width: unset;
        float: none;
    }
    .banner-kitchen_experience {
        display: none;
    }
    .banner-kitchen .fl-row-content-wrap {
        background-size: 150%;
        background-color: #36b9e0;
        background-position: -250px bottom;
        min-height: 570px;
    }
    .banner-kitchen {
        text-align: left;
    }
    .banner-kitchen_maincol {
        padding-right: 0;
        padding-top: 20px;
        padding-left: 15px;
    }
}
@media (max-width: 850px) {
    .banner-kitchen .fl-row-content-wrap {
        background-size: 170%;
    }
}
@media (max-width: 850px) {
    .banner-kitchen .fl-row-content-wrap {
        background-size: 199%;
    }
}
@media (max-width: 580px) {
    .banner-kitchen .fl-row-content-wrap {
        background-size: 300%;
    }
}
html {
    overflow-x: hidden;
}
.genesis-nav-menu .menu-item .sub-menu {
    display: block !important;
}
/* 2 Column Menu */

@media (min-width: 960px) {
    .sub-menu-columns .sub-menu {
        width: 450px !important;
        margin-right: 200px !important;
    }
    .sub-menu-columns ul.sub-menu li {
        display: inline-block;
        float: left;
        width: 220px;
    }
    .sub-menu-columns ul.sub-menu li:nth-child(odd) {
        float: left;
        margin-right: 10px;
        right: 380px;
        position: relative;
    }
    .sub-menu-columns ul.sub-menu li:nth-child(even) {
        float: left;
        right: 380px;
        position: relative;
    }
    .blank-menu-item a {
        color: #fff !important;
    }
    .blank-menu-item a:hover {
        background: white !important;
    }
}
.blank-menu-item {
    display: block;
}
@media (max-width: 960px) {
    .blank-menu-item {
        display: none !important;
    }
}
.vps-points ul li:nth-child(6):before {
    background: url(assets/images/vps-managed-icon.png);
}
.vps-button .button {
    background: #0391d6;
}
@media (max-width: 1170px) {
    .box-colocation {
        margin: 0 auto 30px;
        max-width: 500px;
    }
}
@media (max-width: 1800px) and (min-width: 1401px) {
    #main-banner-text {
        position: absolute;
        top: -16px;
    }
}
@media (max-width: 1400px) and (min-width: 961px) {
    #main-banner-text {
        position: absolute;
        top: -17px;
    }
    #bee-img-col .fl-photo-content {
        position: relative;
        top: 78px;
    }
}
@media (max-width: 1110px) {
    #main-banner-text {
        top: 0;
        left: 25%;
        background: rgba(255, 255, 255, 0.77);
        margin-top: 20px;
        border-radius: 15px;
        padding: 20px;
        width: 50% !important;
    }
    #main-banner-text .fl-separator {
        display: none;
    }
}
@media (max-width: 800px) {
    #main-banner-text {
        top: 0;
        padding: 20px;
        width: unset !important;
        max-width: 80% !important;
        position: unset;
    }
}
@media (max-width: 480px) {
    #main-banner-text .fl-rich-text h1 {
        font-size: 40px;
    }
    #main-banner-text .fl-heading {
        font-size: 21px;
    }
}
#top-banner-features {
    cursor: pointer;
}
.vps-button, .vps-best .vps-button {
    padding: 15px 17px;
}
.vps-button .button {
    width: 100%;
}
.txt-h3 {
    font-size: 30px;
}
.vps-title span {
    display: inline;
    bottom: 23px;
    position: relative;
    padding-right: 12px;
    color: #655143;
    font-family: museosans700;
}
/*@media (min-width: 960px) {
    .sub-menu-columns ul.sub-menu li {
        display: inline-block;
        float: left;
        width: 200px;
    }

    .sub-menu-columns ul.sub-menu li:nth-child(odd) {
        float: left;
        margin-right: 10px;
    }

    .sub-menu-columns ul.sub-menu li:nth-child(even) {
        float: right;
    }

    .sub-menu-columns .sub-menu, .sub-menu-columns .mini-header .sub-menu {
        width: 448px !important;
    }
}*/

.heading-lake {
    font-family: museosans700;
    letter-spacing: .25px;
    font-size: 30px;
    color: #655143;
}
.h3-likeh2 {
    font-size: 32px;
}
.vps-header-titles span, .vps-header-titles-alt span {
    font-size: 29px;
    font-family: museosans700;
}
.vps-header-titles span {
    color: #fff;
    display: block;
    clear: both;
}
.vps-header-titles-alt span {
    color: #655143;
    display: block;
    clear: both;
}
.vps-header-titles h1 {
    color: #fff;
}
@media (min-width: 714px) and (max-width: 983px) {
    .vps-header-titles-alt:first-child {
        margin-top: 23%;
    }
}
.utility-bar-left, .utility-bar-right {
    padding: 0;
}
@media (max-width: 680px) {
    .utility-bar-left span:nth-of-type(3) {
        margin-left: 20px;
    }
    #icl_lang_sel_widget-2 #lang_sel {
        top: -33px;
    }
}
@media (max-width: 480px) {
    #lang_sel>ul>li>ul>li>a {
        position: relative;
        top: 3px;
    }
}
.icl_lang_sel_translated {
    display: none !important;
}
.utility-bar i.fa-phone {
    color: #00A4DE;
}
@media (max-width: 1200px) {
    .utility-bar-left {
        width: unset;
    }
}
.box-xmas-banner {
    text-align: center;
}
.box-xmas-banner h2 {
    color: #ffeda1;
    font-size: 54px;
}
.box-xmas-wood {
    background: url("https://www.snel.com/wp-content/uploads/xmas-board-1.png");
    background-repeat: no-repeat;
    height: 320px;
    background-position-x: center;
}
.banner-blackfriday .fl-module-html .fl-module-content {
    margin: 0;
}
.banner-blackfriday .fl-module-html.aligncenter {
    z-index: 99;
}
.wpml-ls-display {
    display: none;
}
.wpml-ls-menu-item .wpml-ls-flag {
    width: 24px;
    height: 24px;
}
.menu-item-language span:first-child {
    position: relative;
    top: 4px;
}
.menu-item-language .sub-menu li {
    margin-left: 10px !important;
    margin-top: -10px;
}
/* Black friday start*/

.banner-blackfriday {
    position: relative;
    background: #333333;
}
.banner-blackfriday h3 {
    color: #ffd66f;
    text-transform: uppercase;
    font-size: 23px;
    font-weight: normal;
    font-family: museosans500;
    text-shadow: 1px 2px #181818;
}
.txt-orange {
    color: #f69231;
}
.banner-blackfriday .txt-orange {
    font-size: 25px;
}
.banner-blackfriday:after {
    content: "";
    background: url(assets/images/clouds-blackfriday.png) top;
    width: 100%;
    bottom: 0;
    position: absolute;
    height: 166px;
    z-index: 1;
}
.home .banner-blackfriday:after {
    background: none;
}
.row-vpstables .fl-col-group {
    position: relative;
    bottom: 66px;
    z-index: 2;
    width: 100%;
}
.vps-coupon {
    text-align: center;
    display: block;
    padding: 7px 25px;
    font-size: 11px;
    text-transform: uppercase;
    font-family: museosans500;
}
.vps-coupon-code {
    font-size: 18px;
    font-family: museosans900;
    letter-spacing: 1px;
}
/* Black friday end*/

.genesis-nav-menu a {
    padding: 30px 21px;
}
.vps-price {
    font-size: 40px;
    top: 65%;
}
.vps-best .vps-price {
    font-size: 53px;
}
.vps-best .prev-vps-price {
    position: absolute;
    left: 41%;
    top: -40%;
    font-size: 20px;
}
.prev-vps-price {
    position: absolute;
    left: 41%;
    top: -40%;
    font-size: 20px;
}
.row-vpstables .txt-white .one-half {
    width: 58%;
}
.row-vpstables .txt-white .one-half.first {
    width: 39%;
}
.row-vpstables .txt-white h3 {
    font-size: 28px;
    font-weight: normal;
}
.logged-in .livechat {
    display: none;
}
.responsive-table tbody th[scope=row] {
    position: relative;
}
.txt-tip {
    background: #5fb600;
    color: #fff;
    font-family: 'museosans500';
    font-size: 13px;
    display: inline-block;
    position: absolute;
    padding: 0 12px;
    border-radius: 6px;
    top: 4px;
}
.txt-tip:after {
    border-right: 5px solid transparent;
    border-top: 6px solid #5fb600;
    border-left: 5px solid transparent;
    content: "";
    position: absolute;
    top: 25px;
    left: 6px;
    width: 0;
    height: 0;
}
.txt-upto {
    font-size: 11px;
    font-family: 'museosans700';
    position: relative;
    bottom: 4px;
    left: 2px;
}
.txt-old-price {
    position: relative;
    text-decoration: none;
    font-size: 13px;
    opacity: .9;
    display: inline-block;
}
.txt-old-price::after {
    content: "";
    clear: both;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: black;
    transform: rotate(-7deg);
}
@media (max-width: 831px) {
    .txt-tip {
        top: -17px;
        left: 20px;
    }
    .txt-old-price {
        text-decoration: line-through;
    }
    .txt-old-price:after {
        height: 0;
    }
    .responsive-table .btn-small {
        width: 95%;
        margin: 10px 0;
        display: inline-block;
    }
    .responsive-table tbody tr {
        margin-bottom: 40px;
    }
}
.responsive-table .Monthly.price {
    font-size: 19px;
    font-family: 'museosans700';
}
.responsive-table .btn-small {
    padding: 5px 13px;
    font-family: 'museosans900';
}
.banner-halloween .fl-row-content-wrap {
    min-height: 600px;
}
.banner-halloween h1, .banner-halloween h2 {
    color: #F9B233;
    font-family: 'MuseoSans900';
}
.banner-halloween h1 {
    font-size: 64px;
    /*text-shadow: 1px 2px 0 #ffefae;*/
}
.banner-halloween h2 {
    font-size: 33px;
    /*text-shadow: 1px 2px 0 #ffefae;*/
}
.txt-coupon p {
    margin-bottom: 0;
}
.txt-coupon {
    margin-top: auto;
}
.fl-node-58160010dd32a>.fl-row-content-wrap {
    border-bottom-width: 0px !important;
}
@media (max-width: 800px) {
    .page-dedicated .icon-row, .page-dedicated .icon-row-alt .fl-rich-text {
        text-align: center;
    }
    .banner-halloween .fl-row-content {
        padding: 50px 0;
    }
}
@-moz-document url-prefix() {
    .fl-node-58160010dd0ba>div:nth-child(1)>div:nth-child(1) {
        padding-top: 50px;
    }
}
@media (max-width: 480px) {
    .beneath-bottom .wrap {
        padding-left: 1%;
        padding-right: 1%;
    }
    .beneath-bottom #mc4wp_email {
        max-width: 67%;
    }
    .footer-bottom li {
        line-height: 3;
    }
}
@media (max-width: 380px) {
    .beneath-bottom #mc4wp_email {
        font-size: 13px;
    }
    .beneath-bottom #mc4wp_email {
        max-width: 62%;
    }
}
@media (min-width: 380px) {
    .icons-social-footer i {
        font-size: 2.6em;
        width: 1.6em;
        text-align: center;
        line-height: 1.6em;
        border-radius: .8em;
        background: #F9B233;
        color: #524034;
        margin-right: 25px;
    }
}
@media (max-width: 831px) {
    .server-stocks-table .monthly-price {
        position: relative;
        float: right;
        top: -28px;
    }
    .server-stocks-table .is-sale-price {
        padding-top: 20px;
        padding-bottom: 12px;
    }
    .server-stocks-table .is-sale-price .monthly-price {
        position: relative;
        float: right;
        top: -23px;
    }
}
.recapcha-contact-inner>.wpcf7-form-control-wrap {
    padding-bottom: 15px;
}
.page-affiliate .intro-row img {
    max-height: 240px;
    max-width: 210px;
    margin: 22px 0;
}
.managed-server-table td[data-th="Sailor"], .managed-server-table td[data-th="Skipper"], .managed-server-table td[data-th="Captain"] {
    text-align: center;
}
.managed-server-table .button.managed-server-table-action {
    margin-top: 20px;
}
@media (min-width: 480px) {
    .managed-server-table .button.managed-server-table-action {
        position: relative;
        min-width: auto;
        max-width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px 5px;
    }
    .managed-server-table .added-charge {
        position: relative;
        font-size: 14px;
        left: 0;
        right: 0;
        top: 7px;
        bottom: 0;
    }
}
@media (max-width: 824px) {
    .managed-server-table .button.managed-server-table-action {
        padding: 15px 10px;
    }
    .managed-server-table tfoot td[data-th="Sailor"], .managed-server-table tfoot td[data-th="Skipper"], .managed-server-table tfoot td[data-th="Captain"] {
        padding-left: 3px!important;
        padding-right: 3px!important;
    }
}
@media (min-width: 578px) {
    .managed-server-table {
        display: table;
    }
    .managed-server-table-mobile {
        display: none;
    }

    .managed-vps-table.rwd-table th:not(:first-of-type),
    .managed-vps-table.rwd-table td:not(:first-of-type) {
        width: 25%;
    }
}
@media (max-width: 577px) {
    .managed-server-table {
        display: none;
    }
    .managed-server-table-mobile {
        display: table;
    }
    .managed-server-table-mobile th {
        display: table-cell;
        text-align: center;
    }
    .managed-server-table-mobile tr:nth-child(odd) td {
        text-align: center;
    }
    .managed-server-table-mobile tr:nth-child(even) td {
        width: 33%;
        margin: 0;
        float: left;
        text-align: center;
    }
    .managed-server-table .button.managed-server-table-action {
        max-width: 240px;
    }
    .msp-image-hero .fl-photo-img.wp-image-63683.size-full {
        max-width: 100%;
    }
}
.managed-server-table-mobile td[data-th="Sailor"], .managed-server-table-mobile td[data-th="Skipper"], .managed-server-table-mobile td[data-th="Captain"] {
    width: auto;
}
.page-id-31 .fl-module-photo .wp-image-10903 {
    max-height: 100%;
}
@media (min-width: 801px) {
    .mkv-section-title {
        text-align: right;
    }
}
@media (max-width: 800px) {
    .mkv-section-title {
        text-align: inherit;
    }
}
#cookie-notice .button.wp-default, #cookie-notice .button.wp-default:hover {
    padding: 5px 18px;
    font-size: 14px;
    font-family: museosans500;
    background: #0391d6;
    color: #fff;
    display: inline-block;
    border: none;
    text-shadow: none;
}
#cn-notice-text {
    letter-spacing: .25px;
    font-family: museosans500;
    font-size: 14px;
}
#cn-notice-text a {
    color: #0391d6;
}
.snel-tab-table-pricings .responsive-table thead th.unmetered {
    text-align: center;
}
.dedicated-servers-banner {
    padding-bottom: 0!important;
}
@media (max-width: 675px) {
    .snel-tab-table-pricings .snel-tab-tables-module-tab-block {
        background-color: #fcd28f;
    }
    .snel-tab-tables-module .snel-tab-tables-module-title-part .snel-tab-tables-module-tab-block .snel-tab-tables-module-tab.fl-tabs-label:not(.fl-tab-active) {
        background: #fcd28f;
    }
    .snel-tab-tables-module .snel-tab-tables-module-title-part .snel-tab-tables-module-tab-block .snel-tab-tables-module-tab.fl-tabs-label:not(.fl-tab-active):hover {
        background: #fcd28f;
    }
}
.snel-tab-table-pricings .fl-tabs .fl-tabs-panel {
    margin-top: 0!important;
}
.snel-tab-table-pricings .snel-tab-tables-module-content-tabs {
    padding-top: 40px;
}
.mobile-word-break {
    word-break: initial;
}
@media (max-width: 768px) {
    .mobile-word-break {
        -ms-word-wrap: break-word;
        word-wrap: break-word;
    }
}
* {
    margin: 0;
    padding: 0;
}
html {
    height: 100%;
}
body {
    min-height: 100%;
    background: #47382e;
}
body>div {
    background-color: #fff;
}
body>script {
    font-size: 0;
    line-height: 0;
    height: 0;
}
.footer-bottom {
    padding-bottom: 10px;
}
.footer-bottom>p {
    margin: 0;
}
h1.snel-blog-page-title {
    padding-bottom: 40px;
}
.text-center {
    text-align: center;
}
.section-home-nostalgle h3 {
    font-size: 30px;
}
.section-home-nostalgle p {
    font-size: 24px;
    color: #ffffff;
}
.section-home-our-story .fl-col-content.fl-node-content {
    padding: 30px;
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.75);
}
.section-home-our-story h3.fl-heading {
    font-size: 30px;
    margin-bottom: 15px !important;
}
.section-home-our-story p {
    font-size: 24px;
    color: #333333;
}
@media (min-width: 905px) {
    .section-home-testimonials .fl-snel-testimonials-content-image .fl-snel-testimonials-content-image-img {
        margin-top: -11%;
        margin-left: -7%;
        width: 110%;
        max-width: 110%;
    }
}
.text-center {
    text-align: center;
}
.section-home-nostalgle p {
    color: #ffffff;
}
.section-home-nostalgle .nostalgia-mobile-image {
    display: none;
}
@media (max-width: 800px) {
    .section-home-nostalgle .fl-row-content-wrap {
        background-image: none;
        background-color: #838569;
    }
    .section-home-nostalgle .nostalgia-mobile-image {
        display: inline-block;
        text-align: center;
        width: 100%;
    }
}
/*@-moz-document url-prefix() {
    .row-vpstables .fl-col-group {
        position: relative;
        bottom: 130px;
        z-index: 2;
        width: 100%;
    }
}*/

.kb-template-default.single.single-kb .comment-form input.submit {
    border-radius: 15px / 15px;
    display: inline-block;
    background: #f3a01a;
    color: #ffffff;
    font-family: museosans900;
    padding: 12px 62px;
    font-size: 21px;
}

.snel-tab-tables-module-full-width.snel-tab-tables-module-content-part td[data-title="CPU Speed"] {
    min-width: 170px;
}

code {
  background-color: #eaf6ff;
  border: 1px solid #eaf6ff;
}

.EnlighterJS span {
 font-size: 14px !important;
}

.beneath-bottom-sc-image,
.beneath-bottom-sc-image>img {
    vertical-align: middle;
}

.coin-payments-merchant-logo.beneath-bottom-sc-image,
.coin-payments-merchant-logo.beneath-bottom-sc-image>img {
    max-width: 125px;
    padding-left: 10px;
}

@media (min-width: 1075px) {
    .beneath-bottom .wrap .one-third.first,
    .beneath-bottom .wrap .one-third.second,
    .beneath-bottom .wrap .one-third {
        width: 33.33%;
        margin: 0;
    }
}

@media (min-width: 1051px) and (max-width: 1074px) {
    .beneath-bottom .wrap .one-third {
        width: 35%;
        margin: 0;
    }

    .beneath-bottom .wrap .one-third.second {
        width: 30%;
        margin: 0;
    }
}

@media (max-width: 1074px) and (min-width: 332px) {
    .beneath-bottom .wrap .one-third.second {
        padding: 15px 0;
        margin: 0;
    }
}

/*Customize CSS*/
.kb-template-default .anr_captcha_field {
    margin-bottom: 28px;
}

.snel-tab-tables-module .snel-tab-tables-module-title-part .snel-tab-tables-module-tab-block .snel-tab-tables-module-tab.fl-tabs-label {
    background: #689a34;
    padding: 20.5px 30px 20.5px 30px !important;
    border-radius: 0;
}

.snel-tab-tables-module .snel-tab-tables-module-title-part .snel-tab-tables-module-tab-block .snel-tab-tables-module-tab.fl-tabs-label .snel-tab-tables-module-tab-title {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 16px;
}

.snel-tab-tables-module .snel-tab-tables-module-title-part .snel-tab-tables-module-tab-block .snel-tab-tables-module-tab.fl-tabs-label:hover {
    background: #81b04a;
}

.halloween-top-banner .bee-parachute .fl-module-content.fl-node-content>.fl-photo {
    text-align: right;
    margin-right: -150px;
}

.halloween-top-banner .pumpkin>.fl-module-content.fl-node-content {
    text-align: center;
}

.halloween-top-banner .pumpkin .fl-module-content.fl-node-content>.fl-photo {
    margin-top: -185px;
    display: inline-block;
}

/*.home-hero-halloween.halloween-top-banner .pumpkin .fl-module-content.fl-node-content>.fl-photo {
    margin-top: 0;
	  display: inline-block;
}*/

.halloween-pricing-home .fl-snel-price-banner-plans-conteiner {
    /*margin-top: -170px;*/
    /*halloween*/
    margin-top: -135px;
}

.cyber-monday-banner-image img {
    max-width: 65%;
    margin-bottom: 15px;
}

.black-friday-banner-title {
    margin-top: -20px;
    margin-bottom: 20px;
}


.no-bee .fl-snel-price-banner-plan-num-1:before {
    display: none;
}


@media (max-width: 960px) {
    .halloween-top-banner .bee-parachute .fl-module-content.fl-node-content>.fl-photo {
        text-align: center;
        margin-right: 0;
    }
}

.halloween-top-banner .orderbutton {
    z-index: 10;
}

.kb-template-default.single.single-kb .mkb-sidebar .mkb-widget.widget.widget_media_image {
    background: none;
    padding: 0;
}

/*== New Design = Contact Button ==*/

.fl-builder-content .cta-contact-button-1 a.fl-button {
    border: none;
    background-color: transparent;
}

.fl-builder-content .cta-contact-button-1 a.fl-button span {
    color: #f19f30;
    text-transform: uppercase;
    font-size: 18px;
    font-family: museosans700;
}

.fl-builder-content .cta-contact-button-1 a.fl-button i {
    background-color: #1992d4;
    color: #ffffff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    text-align: center;
    padding: 0 8px;
}

/*Christmas banner 2018*/
.xmas-banner-2018 .xmas-banner-2018-content-col {
    position: relative;
}

@media (min-width: 495px) and (max-width: 800px) {
    .xmas-banner-2018 .xmas-banner-2018-content-col {
        padding-bottom: 120px;
    }
}

@media (max-width: 800px) {
    .xmas-banner-2018 .xmas-banner-2018-bee-col {
        position: absolute;
        max-width: 40% !important;
        bottom: 0;
        z-index: 2;
    }
}

.xmas-banner-2018 .xmas-banner-2018-image-top {
    position: absolute;
    top: 10px;
    z-index: 0;
}

.xmas-banner-2018 .xmas-banner-2018-image-bottom {
    position: absolute;
    z-index: 0;
    bottom: 0;
}

.xmas-banner-2018 .xmas-banner-2018-content-text,
.xmas-banner-2018 .xmas-banner-2018-content-image {
    z-index: 1;
}

/*banner end*/

@media (max-width: 800px) {
    h1.opsys-banner-header-m-v {
        font-size: 26px !important;
    }

    .home-m-v .fl-snel-color-row-image-content-text-title {
        font-size: 24px;
    }
}

@media (max-width: 800px) {
    .banner-cloudvps_bee img {
        width: 100%;
    }
}

.mkb-article-text .fl-module-content ul li:before,
.post-template-default.single-post .fl-module-content ul li:before{
    content: "";
}

@media (min-width: 801px) {
    .utility-bar {
        font-size: 14px;
        font-family: museosans500;
        min-height: 35px;
    }

    .wpml-ls-legacy-dropdown ul {
        padding: 15px 15px 5px 15px !important;
    }

    .search-button {
        background: #F69231;
        display: inline-block;
        position: relative;
        padding: 5px 7px;
        z-index: 99;
    }

    .header-image .site-title>a {
        min-height: 50px;
    }

    .genesis-nav-menu a {
        padding: 25px 21px;
    }

    .site-header {
        min-height: 65px;
    }

    .wpml-ls-legacy-dropdown a.wpml-ls-item-toggle:after {
        bottom: 0px;
        right: 0px;
        top: auto;
    }
}

.breadcrumb {
    padding: 8px 0;
}

@media (min-width: 521px) {
    .utility-bar-right>.search-button {
        display: none;
    }

    .utility-bar-right #custom_html-2,
    .utility-bar-right #custom_html-5 {
        padding-top: 0;
        padding-left: 10px;
    }

    .utility-bar-right #custom_html-2 .widget_text,
    .utility-bar-right #custom_html-5 .widget_text {
        padding-top: 0;
    }
}

@media (max-width: 520px) {
    .utility-bar-right #custom_html-2,
    .utility-bar-right #custom_html-5 {
        display: none;
    }

    #text-14 {
        display: block;
        position: absolute;
        top: 9px;
        padding-top: 0;
        right: 8px;
    }

    #text-4,
    #text-13 {
        display: none;
    }

    .tb-mobile-email {
        display: inline-block !important;
    }

    #custom_html-3 .widget_text,
    #custom_html-4 .widget_text {
        padding-bottom: 5px;
    }

    #custom_html-3,
    #custom_html-4 {
        padding-bottom: 2px;
    }
}

@media (min-width: 800px) {

    .utility-bar-left .widget_text,
    .utility-bar-right .widget_text {
        padding-top: 10px;
    }

    .utility-bar .widget_icl_lang_sel_widget {
        margin-left: 0px;
    }
}

@media (min-width: 521px) {
    .utility-bar .widget_icl_lang_sel_widget {
        margin-left: 0px;
    }

    .tb-mobile-email,
    #custom_html-3,
    #custom_html-4 {
        display: none;
    }
}

@media (max-width: 520px) {
    .search-wrap {
        top: 0;
        height: 46px;
    }

    .search-button form {
        height: 46px;
    }

    .utility-bar-right .widget_icl_lang_sel_widget {
        top: 0px;
    }

    .top-switcher>.current-lang {
        max-height: 46px;
    }
}

.wpcf7 .wpcf7-form-control-wrap {
    float: none;
}

.big-white-banner .fl-snel-top-banner .fl-snel-top-banner-top-part-container {
    width: 100%;
    padding: 60px 0;
}

.big-white-banner .fl-snel-top-banner-top-part-hero-title {
    text-align: left;
    color: #ffffff;
    margin-bottom: 25px;
    padding: 0 10px;
}

.big-white-banner .fl-snel-top-banner-top-part-hero-description p {
    text-align: left;
    color: #ffffff;
    font-size: 20px;
    width: 50%;
    padding: 0 10px;
}

.big-white-banner .fl-snel-top-banner-bottom-part {
    background-color: #1482b0;
}

.big-white-banner .fl-snel-top-banner-bottom-part-content-item {
    width: 20%;
}

.big-white-banner .fl-snel-top-banner-bottom-part-block-image-right {
    max-width: 35%;
}

@media (max-width: 715px) {

    .big-white-banner .fl-snel-top-banner-top-part-hero-title,
    .big-white-banner .fl-snel-top-banner-top-part-hero-description p {
        width: 100%;
        text-align: center;

    }
}

@media (min-width: 801px) {
    .fl-col-group-equal-height .fl-col.col-w-50 {
        width: 50%;
        -webkit-box-flex: none;
        -moz-box-flex: none;
        -webkit-flex: none;
        -ms-flex: none;
        flex: none;
    }
}

.width-fixing-1170>.fl-row-content-wrap>.fl-row-content {
    max-width: 1170px;
}

.spism-pricing-plan-col {
    margin-right: 0;
}

.no-padding_tb>.fl-row-content-wrap {
    padding-top: 0;
    padding-bottom: 0;
}

.def-margin-content-top>.fl-module-content {
    margin-top: 5px;
}

.colored-icons .fa.fa-check-circle {
    color: green;
}

.colored-icons .fa.fa-times-circle {
    color: red;
}

@media (min-width: 578px) and (max-width: 800px) {
    .fl-builder-content .ws-table-row-mobile>.fl-row-content-wrap {
        padding-top: 65px;
    }
}

.halloween-banner-mvps.row-vpstables_new .fl-row-content-wrap {
    margin-top: 0px !important;
}

/*@media (max-width: 990px) {
    .halloween-pricing-home .fl-module-snel-price-banner .fl-snel-price-banner-plan-col.recomended-col {
        margin-top: 85px;
    }
}*/

.blackfriday-home-banner-click {
    cursor: pointer;
}

@media (max-width: 1200px) and (min-width: 961px) {
        .site-header .wrap {
        height: auto;
        min-height: 84px;
    }
}

@media (min-width: 480px) {
    .reseller-tbl th img {
        max-width: 125px;
    }
}

@media (max-width: 479px) {
    .reseller-tbl td:first-child,
    .reseller-tbl td:last-child,
    .reseller-tbl td {
        padding: 6px 1px;
    }
}

.reseller-tbl .no-bold {
    font-family: museosans500;
}

.kb-search__input-wrap.mkb-search-theme__clean.kb-search__input-wrap--icons-left .kb-search__input {
    padding-left: 4em!important;
}

.mobile-long-word-break {
    word-break: initial;
}

@media screen and (max-width: 767px) {
    .mobile-long-word-break {
        word-break: break-word;
    }
}

.webhosting-table td[data-th="Humble"], .webhosting-table td[data-th="Strong"], .webhosting-table td[data-th="Brave"] {
    text-align: center;
}

@media (min-width: 578px) {
    .first-col-28 td:first-of-type {
        width: 28%;
    }
}

