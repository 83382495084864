@font-face {
    font-family: museosans300;
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/museosans300.eot);
    src: url(../../assets/fonts/museosans300.eot#iefix) format("embedded-opentype"), url(../../assets/fonts/museosans300.woff) format("woff"), url(../../assets/fonts/museosans300.ttf) format("truetype"), url(../../assets/fonts/museosans300.svg#museosans300) format("svg")
}

@font-face {
    font-family: museosans500;
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/museosans500.eot);
    src: url(../../assets/fonts/museosans500.eot#iefix) format("embedded-opentype"), url(../../assets/fonts/museosans500.woff) format("woff"), url(../../assets/fonts/museosans500.ttf) format("truetype"), url(../../assets/fonts/museosans500.svg#museosans500) format("svg")
}

@font-face {
    font-family: museosans700;
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/museosans700.eot);
    src: url(../../assets/fonts/museosans700.eot#iefix) format("embedded-opentype"), url(../../assets/fonts/museosans700.woff) format("woff"), url(../../assets/fonts/museosans700.ttf) format("truetype"), url(../../assets/fonts/museosans700.svg#museosans700) format("svg")
}

@font-face {
    font-family: museosans900;
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/museosans900.eot);
    src: url(../../assets/fonts/museosans900.eot#iefix) format("embedded-opentype"), url(../../assets/fonts/museosans900.woff) format("woff"), url(../../assets/fonts/museosans900.ttf) format("truetype"), url(../../assets/fonts/museosans900.svg#museosans900) format("svg")
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],
template {
    display: none
}

a {
    background: 0 0
}

a:active,
a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

mark {
    background: #ff0;
    color: #333
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 40px
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type=checkbox],
input[type=radio] {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: 700
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td,
th {
    padding: 0
}

html,
input[type=search] {
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

*,
:before,
:after {
    -moz-box-sizing: inherit;
    box-sizing: inherit
}

.author-box:before,
.clearfix:before,
.entry:before,
.entry-content:before,
.nav-primary:before,
.nav-secondary:before,
.pagination:before,
.site-container:before,
.site-footer:before,
.site-header:before,
.site-inner:before,
.wrap:before {
    content: " ";
    display: table
}

.author-box:after,
.clearfix:after,
.entry:after,
.entry-content:after,
.nav-primary:after,
.nav-secondary:after,
.pagination:after,
.site-container:after,
.site-footer:after,
.site-header:after,
.site-inner:after,
.wrap:after {
    clear: both;
    content: " ";
    display: table
}

html {
    font-size: 62.5%
}

body>div {
    font-size: 1.8rem
}

body {
    background-color: #f5f5f5;
    color: #333;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.425;
    margin: 0
}

a,
button,
input:focus,
input[type=button],
input[type=reset],
input[type=submit],
textarea:focus,
.button,
.gallery img {
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out
}

a {
    color: #F9B233
}

a:hover,
a:focus {
    color: #333;
    text-decoration: none
}

p {
    margin: 0 0 28px;
    padding: 0
}

ol,
ul {
    margin: 0;
    padding: 0
}

li {
    list-style-type: none
}

hr {
    border: 0;
    border-collapse: collapse;
    border-top: 1px solid #ddd;
    clear: both;
    margin: 1em 0
}

b,
strong {
    font-weight: 700
}

blockquote,
cite,
em,
i {
    font-style: italic
}

blockquote {
    margin: 40px
}

blockquote::before {
    content: "\201C";
    display: block;
    font-size: 30px;
    height: 0;
    left: -20px;
    position: relative;
    top: -10px
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 10px
}

h1 {
    font-size: 36px;
    font-size: 3.6rem
}

h2 {
    font-size: 30px;
    font-size: 3rem
}

h3 {
    font-size: 24px;
    font-size: 2.4rem
}

h4 {
    font-size: 20px;
    font-size: 2rem
}

h5 {
    font-size: 18px;
    font-size: 1.8rem
}

h6 {
    font-size: 16px;
    font-size: 1.6rem
}

embed,
iframe,
img,
object,
video,
.wp-caption {
    max-width: 100%
}

img {
    height: auto
}

.featured-content img,
.gallery img {
    width: auto
}

.gallery {
    overflow: hidden
}

.gallery-item {
    float: left;
    margin: 0 0 28px;
    text-align: center
}

.gallery-columns-2 .gallery-item {
    width: 50%
}

.gallery-columns-3 .gallery-item {
    width: 33%
}

.gallery-columns-4 .gallery-item {
    width: 25%
}

.gallery-columns-5 .gallery-item {
    width: 20%
}

.gallery-columns-6 .gallery-item {
    width: 16.6666%
}

.gallery-columns-7 .gallery-item {
    width: 14.2857%
}

.gallery-columns-8 .gallery-item {
    width: 12.5%
}

.gallery-columns-9 .gallery-item {
    width: 11.1111%
}

.gallery img {
    border: 1px solid #ddd;
    height: auto;
    padding: 4px
}

.gallery img:hover,
.gallery img:focus {
    border: 1px solid #999
}

input,
select,
textarea {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #333;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 16px;
    width: 100%
}

input:focus,
textarea:focus {
    border: 1px solid #999;
    outline: 0
}

input[type=checkbox],
input[type=image],
input[type=radio] {
    width: auto
}

::-moz-placeholder {
    color: #333;
    font-weight: 300;
    opacity: 1
}

::-webkit-input-placeholder {
    color: #333;
    font-weight: 300
}

button,
input[type=button],
input[type=reset],
input[type=submit],
.button {
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 16px 24px;
    width: auto
}

button:hover,
input:hover[type=button],
input:hover[type=reset],
input:hover[type=submit],
.button:hover,
button:focus,
input:focus[type=button],
input:focus[type=reset],
input:focus[type=submit],
.button:focus {
    background-color: #F9B233;
    color: #fff
}

.entry-content .button:hover,
.entry-content .button:focus {
    color: #fff
}

.button {
    display: inline-block
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button {
    display: none
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    line-height: 2;
    margin-bottom: 40px;
    width: 100%
}

tbody {
    border-bottom: 1px solid #ddd
}

td,
th {
    text-align: left
}

td {
    border-top: 1px solid #ddd;
    padding: 6px 0
}

th {
    font-weight: 400
}

.screen-reader-text,
.screen-reader-text span,
.screen-reader-shortcut {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden
}

.screen-reader-text:focus,
.screen-reader-shortcut:focus,
.genesis-nav-menu .search input[type=submit]:focus,
.widget_search input[type=submit]:focus {
    clip: auto !important;
    height: auto;
    width: auto;
    display: block;
    font-size: 1em;
    font-weight: 700;
    padding: 15px 23px 14px;
    color: #333;
    background: #fff;
    z-index: 100000;
    text-decoration: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6)
}

.more-link {
    position: relative
}

.wrap {
    margin: 0 auto;
    max-width: 1200px
}

.site-inner {
    width: 100%;
    clear: both;
    padding-top: 40px
}

.content-sidebar-sidebar .content-sidebar-wrap,
.sidebar-content-sidebar .content-sidebar-wrap,
.sidebar-sidebar-content .content-sidebar-wrap {
    width: 980px
}

.content-sidebar-sidebar .content-sidebar-wrap {
    float: left
}

.sidebar-content-sidebar .content-sidebar-wrap,
.sidebar-sidebar-content .content-sidebar-wrap {
    float: right
}

.content {
    float: right;
    width: 800px
}

.content-sidebar .content,
.content-sidebar-sidebar .content,
.sidebar-content-sidebar .content {
    float: left
}

.content-sidebar-sidebar .content,
.sidebar-content-sidebar .content,
.sidebar-sidebar-content .content {
    width: 580px
}

.full-width-content .content {
    width: 100%
}

.sidebar-primary {
    float: right;
    width: 360px
}

.sidebar-content .sidebar-primary,
.sidebar-sidebar-content .sidebar-primary {
    float: left
}

.sidebar-secondary {
    float: left;
    width: 180px
}

.content-sidebar-sidebar .sidebar-secondary {
    float: right
}

.five-sixths,
.four-sixths,
.one-fourth,
.one-half,
.one-sixth,
.one-third,
.three-fourths,
.three-sixths,
.two-fourths,
.two-sixths,
.two-thirds {
    float: left;
    margin-left: 2.564102564102564%
}

.one-half,
.three-sixths,
.two-fourths {
    width: 48.717948717948715%
}

.one-third,
.two-sixths {
    width: 31.623931623931625%
}

.four-sixths,
.two-thirds {
    width: 65.81196581196582%
}

.one-fourth {
    width: 23.076923076923077%
}

.three-fourths {
    width: 74.35897435897436%
}

.one-sixth {
    width: 14.52991452991453%
}

.five-sixths {
    width: 82.90598290598291%
}

.first {
    clear: both;
    margin-left: 0
}

.avatar {
    float: left
}

.alignleft .avatar,
.author-box .avatar {
    margin-right: 24px
}

.alignright .avatar {
    margin-left: 24px
}

.comment .avatar {
    margin: 0 16px 24px 0
}

.breadcrumb {
    margin-bottom: 20px
}

.archive-description,
.author-box {
    background-color: #fff;
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 40px;
    padding: 40px
}

.author-box-title {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 4px
}

.archive-description p:last-child,
.author-box p:last-child {
    margin-bottom: 0
}

.search-form {
    overflow: hidden
}

.site-header .search-form {
    float: right;
    margin-top: 12px
}

.entry-content .search-form,
.site-header .search-form {
    width: 50%
}

.genesis-nav-menu .search input[type=submit],
.widget_search input[type=submit] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px
}

.archive-title {
    font-size: 20px;
    font-size: 2rem
}

.entry-title {
    font-size: 36px;
    font-size: 3.6rem
}

.entry-title a,
.sidebar .widget-title a {
    color: #333
}

.entry-title a:hover,
.entry-title a:focus {
    color: #F9B233
}

.widget-title {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 20px
}

a.aligncenter img {
    display: block;
    margin: 0 auto
}

a.alignnone {
    display: inline-block
}

.alignleft {
    float: left;
    text-align: left
}

.alignright {
    float: right;
    text-align: right
}

a.alignleft,
a.alignnone,
a.alignright {
    max-width: 100%
}

img.centered,
.aligncenter {
    display: block;
    margin: 0 auto 24px
}

img.alignnone,
.alignnone {
    margin-bottom: 12px
}

a.alignleft,
img.alignleft,
.wp-caption.alignleft {
    margin: 0 24px 24px 0
}

a.alignright,
img.alignright,
.wp-caption.alignright {
    margin: 0 0 24px 24px
}

.wp-caption-text {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center
}

.entry-content p.wp-caption-text {
    margin-bottom: 0
}

.entry-content .wp-audio-shortcode,
.entry-content .wp-playlist,
.entry-content .wp-video {
    margin: 0 0 28px
}

.widget {
    word-wrap: break-word
}

.widget ol>li {
    list-style-position: inside;
    list-style-type: decimal;
    padding-left: 20px;
    text-indent: -20px
}

.widget li li {
    border: 0;
    margin: 0 0 0 30px;
    padding: 0
}

.widget_calendar table {
    width: 100%
}

.widget_calendar td,
.widget_calendar th {
    text-align: center
}

.featured-content .entry {
    border-bottom: 2px solid #f5f5f5;
    margin-bottom: 20px;
    padding: 0 0 24px
}

.featured-content .entry-title {
    font-size: 20px;
    font-size: 2rem
}

.enews-widget,
.enews-widget .widget-title {
    color: #fff
}

.sidebar .widget.enews-widget {
    background-color: #333
}

.enews-widget input,
.enews-widget input:focus {
    border: 1px solid #333
}

.enews-widget input {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 16px
}

.enews-widget input[type=submit] {
    background-color: #F9B233;
    color: #fff;
    margin: 0;
    width: 100%
}

.enews-widget input:hover[type=submit],
.enews-widget input:focus[type=submit] {
    background-color: #fff;
    color: #333
}

.enews form+p {
    margin-top: 24px
}

#wpstats {
    display: none
}

.genesis-skip-link {
    margin: 0
}

.genesis-skip-link li {
    height: 0;
    width: 0;
    list-style: none
}

:focus {
    color: #333;
    outline: #ccc solid 1px
}

.site-header {
    background-color: #fff;
    min-height: 160px
}

.site-header .wrap {
    padding: 0
}

.title-area {
    float: left;
    padding: 20px 0;
    width: 360px
}

.header-full-width .title-area {
    width: 100%
}

.site-title {
    font-size: 32px;
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 1.2
}

.site-title a,
.site-title a:hover,
.site-title a:focus {
    color: #333
}

.site-description {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.5
}

.site-description,
.site-title {
    margin-bottom: 0
}

.header-image .site-description,
.header-image .site-title {
    display: block;
    text-indent: -9999px
}

.site-header .widget-area {
    float: right;
    text-align: right;
    width: 800px
}

.genesis-nav-menu {
    clear: both;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1;
    width: 100%
}

.genesis-nav-menu .menu-item {
    display: inline-block;
    text-align: left
}

.genesis-nav-menu a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 30px 24px
}

.genesis-nav-menu a:hover,
.genesis-nav-menu a:focus,
.genesis-nav-menu .current-menu-item>a,
.genesis-nav-menu .sub-menu .current-menu-item>a:hover,
.genesis-nav-menu .sub-menu .current-menu-item>a:focus {
    color: #F9B233
}

.genesis-nav-menu .sub-menu {
    left: -9999px;
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity .4s ease-in-out;
    transition: opacity .4s ease-in-out;
    width: 200px;
    z-index: 99
}

.genesis-nav-menu .sub-menu a {
    background-color: #fff;
    border: 1px solid #eee;
    border-top: 0;
    font-size: 14px;
    font-size: 1.4rem;
    padding: 20px;
    position: relative;
    width: 200px;
    word-wrap: break-word
}

.genesis-nav-menu .sub-menu .sub-menu {
    margin: -55px 0 0 199px
}

.genesis-nav-menu .menu-item:hover {
    position: static
}

.genesis-nav-menu .menu-item:hover>.sub-menu {
    left: auto;
    opacity: 1
}

.genesis-nav-menu>.first>a {
    padding-left: 0
}

.genesis-nav-menu>.last>a {
    padding-right: 0
}

.genesis-nav-menu>.right {
    color: #fff;
    float: right;
    list-style-type: none;
    padding: 30px 0
}

.genesis-nav-menu>.right>a {
    display: inline;
    padding: 0
}

.genesis-nav-menu>.rss>a {
    margin-left: 48px
}

.genesis-nav-menu>.search {
    padding: 10px 0 0
}

.menu .menu-item:focus {
    position: static
}

.menu .menu-item>a:focus+ul.sub-menu,
.menu .menu-item.sfHover>ul.sub-menu {
    left: auto;
    opacity: 1
}

.site-header .sub-menu {
    border-top: 1px solid #eee
}

.site-header .sub-menu .sub-menu {
    margin-top: -56px
}

.site-header .genesis-nav-menu li li {
    margin-left: 0
}

.nav-primary {
    background-color: #333
}

.nav-primary .genesis-nav-menu a:hover,
.nav-primary .genesis-nav-menu a:focus,
.nav-primary .genesis-nav-menu .current-menu-item>a {
    color: #fff
}

.nav-primary .genesis-nav-menu .sub-menu a {
    color: #333
}

.nav-primary .genesis-nav-menu .sub-menu a:hover,
.nav-primary .genesis-nav-menu .sub-menu a:focus,
.nav-primary .genesis-nav-menu .sub-menu .current-menu-item>a {
    color: #F9B233
}

.nav-secondary {
    background-color: #fff
}

.entry {
    margin-bottom: 40px;
    padding: 50px 60px
}

.content .entry {
    background-color: #fff
}

.entry-content ol,
.entry-content ul {
    margin-bottom: 28px;
    margin-left: 40px
}

.entry-content ol>li {
    list-style-type: decimal
}

.entry-content ul>li {
    list-style-type: disc
}

.entry-content ol ol,
.entry-content ul ul {
    margin-bottom: 0
}

.entry-content code {
    background-color: #333;
    color: #ddd
}

p.entry-meta {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 0
}

.entry-header .entry-meta {
    margin-bottom: 24px
}

.entry-footer .entry-meta {
    border-top: 2px solid #f5f5f5;
    padding-top: 24px
}

.entry-categories,
.entry-tags {
    display: block
}

.entry-comments-link::before {
    content: "\2014";
    margin: 0 6px 0 2px
}

.pagination {
    clear: both;
    margin: 40px 0
}

.adjacent-entry-pagination {
    margin-bottom: 0
}

.archive-pagination li {
    display: inline
}

.archive-pagination li a {
    background-color: #333;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 8px 12px;
    text-decoration: none
}

.archive-pagination li a:hover,
.archive-pagination li a:focus,
.archive-pagination .active a {
    background-color: #F9B233;
    text-decoration: underline
}

.comment-respond,
.entry-comments,
.entry-pings {
    background-color: #fff;
    margin-bottom: 40px
}

.comment-respond,
.entry-pings {
    padding: 40px 40px 16px
}

.entry-comments {
    padding: 40px
}

.comment-header {
    font-size: 16px;
    font-size: 1.6rem
}

li.comment {
    background-color: #f5f5f5;
    border: 2px solid #fff;
    border-right: 0
}

.comment-content {
    clear: both;
    word-wrap: break-word
}

.comment-list li {
    margin-top: 24px;
    padding: 32px
}

.comment-list li li {
    margin-right: -32px
}

.comment-respond input[type=email],
.comment-respond input[type=text],
.comment-respond input[type=url] {
    width: 50%
}

.comment-respond label {
    display: block;
    margin-right: 12px
}

.entry-comments .comment-author {
    margin-bottom: 0
}

.entry-pings .reply {
    display: none
}

.form-allowed-tags {
    background-color: #f5f5f5;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 24px
}

.sidebar {
    font-size: 16px;
    font-size: 1.6rem
}

.sidebar li {
    border-bottom: 1px dotted #ddd;
    margin-bottom: 10px;
    padding-bottom: 10px
}

.sidebar p:last-child,
.sidebar ul>li:last-child {
    margin-bottom: 0
}

.sidebar .widget {
    background-color: #fff;
    margin-bottom: 40px;
    padding: 40px
}

.site-footer {
    background-color: #fff;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1;
    padding: 40px 0;
    text-align: center
}

.site-footer p {
    margin-bottom: 0
}

@media only screen and (max-width:1200px) {

    .wrap {
        max-width: 960px
    }

    .content-sidebar-sidebar .content-sidebar-wrap,
    .sidebar-content-sidebar .content-sidebar-wrap,
    .sidebar-sidebar-content .content-sidebar-wrap {
        width: 740px
    }

    .content,
    .site-header .widget-area {
        width: 620px
    }

    .sidebar-content-sidebar .content,
    .sidebar-sidebar-content .content,
    .content-sidebar-sidebar .content {
        width: 400px
    }

    .sidebar-primary,
    .title-area {
        width: 300px
    }
}

@media only screen and (max-width:960px) {

    .wrap {
        max-width: 800px
    }

    .content,
    .content-sidebar-sidebar .content,
    .content-sidebar-sidebar .content-sidebar-wrap,
    .sidebar-content-sidebar .content,
    .sidebar-content-sidebar .content-sidebar-wrap,
    .sidebar-primary,
    .sidebar-secondary,
    .sidebar-sidebar-content .content,
    .sidebar-sidebar-content .content-sidebar-wrap,
    .site-header .widget-area,
    .title-area {
        width: 100%
    }

    .header-image .site-title>a {
        background-position: center top
    }

    .genesis-nav-menu li,
    .site-header ul.genesis-nav-menu,
    .site-header .search-form {
        float: none
    }

    .genesis-nav-menu,
    .site-description,
    .site-header .title-area,
    .site-header .search-form,
    .site-title {
        text-align: center
    }

    .genesis-nav-menu a,
    .genesis-nav-menu>.first>a,
    .genesis-nav-menu>.last>a {
        padding: 20px 16px
    }

    .site-header .search-form {
        margin: 16px auto
    }

    .genesis-nav-menu li.right {
        display: none
    }
}

@media only screen and (max-width:800px) {
    body {
        background-color: #fff;
        font-size: 16px;
        font-size: 1.6rem
    }

    .site-inner,
    .wrap {
        padding-left: 5%;
        padding-right: 5%
    }

    .archive-description,
    .author-box,
    .comment-respond,
    .entry,
    .entry-comments,
    .entry-pings,
    .sidebar .widget,
    .site-header {
        padding: 0
    }

    .archive-pagination li a {
        margin-bottom: 4px
    }

    .five-sixths,
    .four-sixths,
    .one-fourth,
    .one-half,
    .one-sixth,
    .one-third,
    .three-fourths,
    .three-sixths,
    .two-fourths,
    .two-sixths,
    .two-thirds {
        margin: 0;
        width: 100%
    }

    .sidebar .widget.enews-widget {
        padding: 40px
    }
}

@media print {

    *,
    :before,
    :after {
        background: transparent !important;
        box-shadow: none !important;
        color: #000 !important;
        text-shadow: none !important
    }

    a,
    a:visited {
        text-decoration: underline
    }

    a[href]:after {
        content: " ("attr(href) ")"
    }

    abbr[title]:after {
        content: " ("attr(title) ")"
    }

    a[href^="javascript:"]:after,
    a[href^="#"]:after,
    .site-title>a:after {
        content: ""
    }

    thead {
        display: table-header-group
    }

    img,
    tr {
        page-break-inside: avoid
    }

    img {
        max-width: 100% !important
    }

    @page {
        margin: 2cm .5cm
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3
    }

    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }

    .content,
    .content-sidebar {
        width: 100%
    }

    button,
    input,
    select,
    textarea,
    .breadcrumb,
    .comment-edit-link,
    .comment-form,
    .comment-list .reply a,
    .comment-reply-title,
    .edit-link,
    .entry-comments-link,
    .entry-footer,
    .genesis-box,
    .header-widget-area,
    .hidden-print,
    .home-top,
    .nav-primary,
    .nav-secondary,
    .post-edit-link,
    .sidebar {
        display: none !important
    }

    .title-area {
        text-align: center;
        width: 100%
    }

    .site-title>a {
        margin: 0;
        text-decoration: none;
        text-indent: 0
    }

    .site-inner {
        padding-top: 0;
        position: relative;
        top: -100px
    }

    .author-box {
        margin-bottom: 0
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        orphans: 3;
        page-break-after: avoid;
        page-break-inside: avoid;
        widows: 3
    }

    img {
        page-break-after: avoid;
        page-break-inside: avoid
    }

    blockquote,
    pre,
    table {
        page-break-inside: avoid
    }

    dl,
    ol,
    ul {
        page-break-before: avoid
    }
}

body {
    background: #fff
}

a {
    color: #F9B233;
    outline: 0
}

button {
    outline: 0
}

a:hover {
    color: #ffb42d
}

::-moz-selection {
    background: #e4ac48;
    text-shadow: none
}

::selection {
    background: #e4ac48;
    text-shadow: none
}

h1 {
    font-family: museosans900;
    letter-spacing: .25px;
    color: #655143;
    font-size: 44px
}

h2,
h3,
h4,
h5,
h6 {
    font-family: museosans700;
    letter-spacing: .25px;
    color: #655143
}

h2 {
    font-size: 32px
}

h3 {
    font-size: 30px
}

p {
    letter-spacing: .25px;
    font-family: museosans500;
    font-size: 18px
}

body {
    font-family: museosans300
}

.txt-white h2,
.txt-white h3,
.txt-white h4,
.txt-white h5,
.txt-white h6,
.txt-white p {
    color: #fff
}

.wrap {
    max-width: 1170px
}

.wpcf7 .rtp-form-field {
    clear: both;
    margin-bottom: 15px;
    overflow: hidden
}

.wpcf7 label {
    font-weight: 700;
    float: left;
    margin-bottom: 4px;
    width: 100%
}

.wpcf7 .wpcf7-form-control-wrap {
    float: left;
    display: block;
    margin-bottom: 0;
    width: 100%
}

.wpcf7 input {
    font-size: 1em;
    min-height: 56px;
    padding: 0 5px
}

.wpcf7 input[type=radio] {
    min-height: 15px
}

.wpcf7 p {
    margin-bottom: 0;
    margin-top: 0;
    line-height: 2.4
}

.wpcf7 textarea {
    height: 100px;
    padding: 5px;
    width: 100%
}

.wpcf7 input,
.wpcf7 select,
.wpcf7 textarea {
    border-radius: 14px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    padding: 13px
}

.wpcf7 .wpcf7-validation-errors {
    background-color: #f2dede;
    border-color: #eed3d7;
    color: #b94a48;
    clear: both;
    margin: 15px 0 0;
    overflow: hidden;
    padding: 8px 10px
}

.wpcf7 .wpcf7-mail-sent-ok {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #468847;
    clear: both;
    margin: 15px 0 0;
    overflow: hidden;
    padding: 10px
}

.wpcf7 .wpcf7-not-valid-tip {
    border: 0;
    color: #b94a48;
    font-size: 13px;
    left: 0;
    padding: 0 2px;
    position: absolute;
    top: 5px;
    width: 97%;
    z-index: 100;
    text-align: right
}

.wpcf7 span.required {
    color: #b94a48
}

form.wpcf7-form {
    padding: 0 !important
}

#content .wpcf7 .btn-container {
    text-align: center;
    margin-bottom: 0;
    overflow: visible;
    position: relative
}

#content img.ajax-loader {
    bottom: -25px;
    left: 49%;
    margin: 10px 0 0;
    position: absolute
}

.ie8 .wpcf7 input {
    line-height: 35px
}

@-ms-viewport {
    width: device-width
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important
}

@media (max-width:899px) {
    .visible-xs {
        display: block !important
    }

    table.visible-xs {
        display: table
    }

    tr.visible-xs {
        display: table-row !important
    }

    th.visible-xs,
    td.visible-xs {
        display: table-cell !important
    }
}

@media (max-width:899px) {
    .visible-xs-block {
        display: block !important
    }
}

@media (max-width:899px) {
    .visible-xs-inline {
        display: inline !important
    }
}

@media (max-width:899px) {
    .visible-xs-inline-block {
        display: inline-block !important
    }
}

@media (max-width:900px) {
    .visible-sm {
        display: block !important
    }

    table.visible-sm {
        display: table
    }

    tr.visible-sm {
        display: table-row !important
    }

    th.visible-sm,
    td.visible-sm {
        display: table-cell !important
    }
}

@media (min-width:900px) and (max-width:899px) {
    .visible-sm-block {
        display: block !important
    }
}

@media (min-width:900px) and (max-width:899px) {
    .visible-sm-inline {
        display: inline !important
    }
}

@media (min-width:900px) and (max-width:899px) {
    .visible-sm-inline-block {
        display: inline-block !important
    }
}

@media (min-width:900px) and (max-width:1199px) {
    .visible-md {
        display: block !important
    }

    table.visible-md {
        display: table
    }

    tr.visible-md {
        display: table-row !important
    }

    th.visible-md,
    td.visible-md {
        display: table-cell !important
    }
}

@media (min-width:900px) and (max-width:1199px) {
    .visible-md-block {
        display: block !important
    }
}

@media (min-width:900px) and (max-width:1199px) {
    .visible-md-inline {
        display: inline !important
    }
}

@media (min-width:900px) and (max-width:1199px) {
    .visible-md-inline-block {
        display: inline-block !important
    }
}

@media (min-width:1200px) {
    .visible-lg {
        display: block !important
    }

    table.visible-lg {
        display: table
    }

    tr.visible-lg {
        display: table-row !important
    }

    th.visible-lg,
    td.visible-lg {
        display: table-cell !important
    }
}

@media (min-width:1200px) {
    .visible-lg-block {
        display: block !important
    }
}

@media (min-width:1200px) {
    .visible-lg-inline {
        display: inline !important
    }
}

@media (min-width:1200px) {
    .visible-lg-inline-block {
        display: inline-block !important
    }
}

@media (max-width:899px) {
    .hidden-xs {
        display: none !important
    }
}

@media (min-width:900px) and (max-width:899px) {
    .hidden-sm {
        display: none !important
    }
}

@media (min-width:900px) and (max-width:1199px) {
    .hidden-md {
        display: none !important
    }
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important
    }
}

.visible-print {
    display: none !important
}

@media print {
    .visible-print {
        display: block !important
    }

    table.visible-print {
        display: table
    }

    tr.visible-print {
        display: table-row !important
    }

    th.visible-print,
    td.visible-print {
        display: table-cell !important
    }
}

.visible-print-block {
    display: none !important
}

@media print {
    .visible-print-block {
        display: block !important
    }
}

.visible-print-inline {
    display: none !important
}

@media print {
    .visible-print-inline {
        display: inline !important
    }
}

.visible-print-inline-block {
    display: none !important
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important
    }
}

@media print {
    .hidden-print {
        display: none !important
    }
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

@media (min-width:900px) {
    .container {
        width: 750px
    }
}

@media (min-width:900px) {
    .container {
        width: 970px
    }
}

@media (min-width:1200px) {
    .container {
        width: 1170px
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.row {
    margin-left: -15px;
    margin-right: -15px
}

.col,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.col,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    float: left
}

.col-xs-12 {
    width: 100%
}

.col-xs-11 {
    width: 91.66666667%
}

.col-xs-10 {
    width: 83.33333333%
}

.col-xs-9 {
    width: 75%
}

.col-xs-8 {
    width: 66.66666667%
}

.col-xs-7 {
    width: 58.33333333%
}

.col-xs-6 {
    width: 50%
}

.col-xs-5 {
    width: 41.66666667%
}

.col-xs-4 {
    width: 33.33333333%
}

.col-xs-3 {
    width: 25%
}

.col-xs-2 {
    width: 16.66666667%
}

.col-xs-1 {
    width: 8.33333333%
}

.col-xs-pull-12 {
    right: 100%
}

.col-xs-pull-11 {
    right: 91.66666667%
}

.col-xs-pull-10 {
    right: 83.33333333%
}

.col-xs-pull-9 {
    right: 75%
}

.col-xs-pull-8 {
    right: 66.66666667%
}

.col-xs-pull-7 {
    right: 58.33333333%
}

.col-xs-pull-6 {
    right: 50%
}

.col-xs-pull-5 {
    right: 41.66666667%
}

.col-xs-pull-4 {
    right: 33.33333333%
}

.col-xs-pull-3 {
    right: 25%
}

.col-xs-pull-2 {
    right: 16.66666667%
}

.col-xs-pull-1 {
    right: 8.33333333%
}

.col-xs-pull-0 {
    right: auto
}

.col-xs-push-12 {
    left: 100%
}

.col-xs-push-11 {
    left: 91.66666667%
}

.col-xs-push-10 {
    left: 83.33333333%
}

.col-xs-push-9 {
    left: 75%
}

.col-xs-push-8 {
    left: 66.66666667%
}

.col-xs-push-7 {
    left: 58.33333333%
}

.col-xs-push-6 {
    left: 50%
}

.col-xs-push-5 {
    left: 41.66666667%
}

.col-xs-push-4 {
    left: 33.33333333%
}

.col-xs-push-3 {
    left: 25%
}

.col-xs-push-2 {
    left: 16.66666667%
}

.col-xs-push-1 {
    left: 8.33333333%
}

.col-xs-push-0 {
    left: auto
}

.col-xs-offset-12 {
    margin-left: 100%
}

.col-xs-offset-11 {
    margin-left: 91.66666667%
}

.col-xs-offset-10 {
    margin-left: 83.33333333%
}

.col-xs-offset-9 {
    margin-left: 75%
}

.col-xs-offset-8 {
    margin-left: 66.66666667%
}

.col-xs-offset-7 {
    margin-left: 58.33333333%
}

.col-xs-offset-6 {
    margin-left: 50%
}

.col-xs-offset-5 {
    margin-left: 41.66666667%
}

.col-xs-offset-4 {
    margin-left: 33.33333333%
}

.col-xs-offset-3 {
    margin-left: 25%
}

.col-xs-offset-2 {
    margin-left: 16.66666667%
}

.col-xs-offset-1 {
    margin-left: 8.33333333%
}

.col-xs-offset-0 {
    margin-left: 0
}

@media (min-width:900px) {

    .col,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
        float: left
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-11 {
        width: 91.66666667%
    }

    .col-sm-10 {
        width: 83.33333333%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-8 {
        width: 66.66666667%
    }

    .col-sm-7 {
        width: 58.33333333%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-5 {
        width: 41.66666667%
    }

    .col-sm-4 {
        width: 33.33333333%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-2 {
        width: 16.66666667%
    }

    .col-sm-1 {
        width: 8.33333333%
    }

    .col-sm-pull-12 {
        right: 100%
    }

    .col-sm-pull-11 {
        right: 91.66666667%
    }

    .col-sm-pull-10 {
        right: 83.33333333%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-8 {
        right: 66.66666667%
    }

    .col-sm-pull-7 {
        right: 58.33333333%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-5 {
        right: 41.66666667%
    }

    .col-sm-pull-4 {
        right: 33.33333333%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-2 {
        right: 16.66666667%
    }

    .col-sm-pull-1 {
        right: 8.33333333%
    }

    .col-sm-pull-0 {
        right: auto
    }

    .col-sm-push-12 {
        left: 100%
    }

    .col-sm-push-11 {
        left: 91.66666667%
    }

    .col-sm-push-10 {
        left: 83.33333333%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-8 {
        left: 66.66666667%
    }

    .col-sm-push-7 {
        left: 58.33333333%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-5 {
        left: 41.66666667%
    }

    .col-sm-push-4 {
        left: 33.33333333%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-2 {
        left: 16.66666667%
    }

    .col-sm-push-1 {
        left: 8.33333333%
    }

    .col-sm-push-0 {
        left: auto
    }

    .col-sm-offset-12 {
        margin-left: 100%
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%
    }

    .col-sm-offset-0 {
        margin-left: 0
    }
}

@media (min-width:902px) {

    .col,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-11 {
        width: 91.66666667%
    }

    .col-md-10 {
        width: 83.33333333%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-8 {
        width: 66.66666667%
    }

    .col-md-7 {
        width: 58.33333333%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-5 {
        width: 41.66666667%
    }

    .col-md-4 {
        width: 33.33333333%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-2 {
        width: 16.66666667%
    }

    .col-md-1 {
        width: 8.33333333%
    }

    .col-md-pull-12 {
        right: 100%
    }

    .col-md-pull-11 {
        right: 91.66666667%
    }

    .col-md-pull-10 {
        right: 83.33333333%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-8 {
        right: 66.66666667%
    }

    .col-md-pull-7 {
        right: 58.33333333%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-5 {
        right: 41.66666667%
    }

    .col-md-pull-4 {
        right: 33.33333333%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-2 {
        right: 16.66666667%
    }

    .col-md-pull-1 {
        right: 8.33333333%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-push-12 {
        left: 100%
    }

    .col-md-push-11 {
        left: 91.66666667%
    }

    .col-md-push-10 {
        left: 83.33333333%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-8 {
        left: 66.66666667%
    }

    .col-md-push-7 {
        left: 58.33333333%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-5 {
        left: 41.66666667%
    }

    .col-md-push-4 {
        left: 33.33333333%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-2 {
        left: 16.66666667%
    }

    .col-md-push-1 {
        left: 8.33333333%
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-offset-12 {
        margin-left: 100%
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%
    }

    .col-md-offset-0 {
        margin-left: 0
    }
}

@media (min-width:1200px) {

    .col,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
        float: left
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-11 {
        width: 91.66666667%
    }

    .col-lg-10 {
        width: 83.33333333%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-8 {
        width: 66.66666667%
    }

    .col-lg-7 {
        width: 58.33333333%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-5 {
        width: 41.66666667%
    }

    .col-lg-4 {
        width: 33.33333333%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-2 {
        width: 16.66666667%
    }

    .col-lg-1 {
        width: 8.33333333%
    }

    .col-lg-pull-12 {
        right: 100%
    }

    .col-lg-pull-11 {
        right: 91.66666667%
    }

    .col-lg-pull-10 {
        right: 83.33333333%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-8 {
        right: 66.66666667%
    }

    .col-lg-pull-7 {
        right: 58.33333333%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-5 {
        right: 41.66666667%
    }

    .col-lg-pull-4 {
        right: 33.33333333%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-2 {
        right: 16.66666667%
    }

    .col-lg-pull-1 {
        right: 8.33333333%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-push-12 {
        left: 100%
    }

    .col-lg-push-11 {
        left: 91.66666667%
    }

    .col-lg-push-10 {
        left: 83.33333333%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-8 {
        left: 66.66666667%
    }

    .col-lg-push-7 {
        left: 58.33333333%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-5 {
        left: 41.66666667%
    }

    .col-lg-push-4 {
        left: 33.33333333%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-2 {
        left: 16.66666667%
    }

    .col-lg-push-1 {
        left: 8.33333333%
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-offset-12 {
        margin-left: 100%
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }
}

.clearfix,
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
    content: " ";
    display: table
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
    clear: both
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.pull-right {
    float: right !important
}

.pull-left {
    float: left !important
}

*,
:before,
:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.chromeframe {
    margin: .2em 0;
    background: #ccc;
    color: #000;
    padding: .2em 0
}

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    *text-indent: -9999px
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 100%
}

.hidden {
    display: none !important;
    visibility: hidden
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

.invisible {
    visibility: hidden
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table
}

.clearfix:after {
    clear: both
}

.clearfix {
    *zoom: 1
}

a,
a:active,
a img {
    outline: 0
}

.benefits-list .fl-rich-text {
    text-align: center
}

.benefits-list .fl-rich-text ul {
    text-align: left;
    display: inline-block;
    max-width: 350px
}

@media (max-width:480px) {
    .benefits-list .fl-rich-text ul {
        max-width: 300px
    }
}

@media (max-width:375px) {
    .benefits-list .fl-rich-text ul {
        max-width: 250px
    }
}

.benefits-list .fl-rich-text li {
    letter-spacing: .25px;
    font-size: 17px;
    text-indent: -9px
}

.benefits-list .fl-rich-text li:before {
    font-family: FontAwesome;
    content: "\f00c";
    display: inline-block;
    padding-right: 3px;
    color: #00CE3C
}

.to-front {
    z-index: 999;
    position: relative
}

.banner {
    min-height: 440px
}

.banner .fl-row-content-wrap {
    min-height: 440px
}

.banner h1 {
    font-family: MuseoSans700;
    color: #ffefae;
    font-size: 44px;
    font-weight: 800;
    line-height: 1.1
}

@media (max-width:375px) {
    .banner h1 {
        font-size: 39px
    }
}

.banner h2 {
    color: #fff;
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 1.4
}

.banner p {
    font-family: museosans500;
    color: #fff;
    font-size: 21px;
    margin-bottom: 24px
}

.banner h4 {
    color: #fff
}

.banner .white-list li {
    font-size: 21px;
    margin-bottom: 5px
}

.page-affiliate .banner .fl-row-content-wrap {}

@media (max-width:1200px) {
    .page-affiliate .banner .fl-row-content-wrap {
        background-position: -193px center !important
    }
}

@media (max-width:800px) {
    .page-affiliate .banner .fl-row-content-wrap {
        background-position: center center !important
    }
}

.page-upgrades .banner p {
    font-family: museosans700;
    font-size: 22px
}

.banner-vps h1,
.banner-vps h3 {
    color: #fff
}

.banner-features h3 {
    color: #f3a01a;
    font-family: museosans900;
    font-size: 50px
}

.banner-features h4 {
    color: #f3a01a;
    font-size: 28px
}

.banner-features h1 {
    font-family: museosans900;
    font-size: 28px;
    color: #655143
}

.banner-features .fl-row-content-wrap {}

@media (max-width:800px) {
    .banner-features .fl-row-content-wrap {
        background-position: left center !important
    }
}

.bee-left .fl-col-group,
.icon-row-alt .fl-col-group {}

@media (max-width:800px) {

    .bee-left .fl-col-group,
    .icon-row-alt .fl-col-group {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%
    }
}

.bee-left .fl-col:first-child,
.icon-row-alt .fl-col:first-child {}

@media (max-width:800px) {

    .bee-left .fl-col:first-child,
    .icon-row-alt .fl-col:first-child {
        -webkit-flex-basis: 100%;
        -ms-flex: 0 1 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }
}

.bee-left .fl-col:nth-child(2),
.icon-row-alt .fl-col:nth-child(2) {}

@media (max-width:800px) {

    .bee-left .fl-col:nth-child(2),
    .icon-row-alt .fl-col:nth-child(2) {
        -webkit-flex-basis: 100%;
        -ms-flex: 0 1 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }
}

.banner-right h1,
.banner-right h2,
.banner-right h4,
.banner-right p,
.banner-right .button {
    float: right;
    text-align: right
}

@media (max-width:800px) {

    .banner-right h1,
    .banner-right h2,
    .banner-right h4,
    .banner-right p,
    .banner-right .button {
        float: none;
        text-align: left
    }
}

.banner-edge-imgright .fl-col:first-child {
    padding-left: 8%
}

@media (max-width:800px) {
    .banner-edge-imgright .fl-col:first-child {
        padding-left: 0
    }
}

.banner-edge-imgright .fl-module-content {
    margin: 0
}

.banner-edge-imgleft .fl-row-content-wrap {
    min-height: 0
}

.banner-edge-imgleft .fl-module-content {
    margin: 0
}

.banner-edge-imgleft .banner-edge-txt .fl-module-content {
    margin: 5px
}

.banner-edge-imgleft .fl-col:first-child {}

@media (max-width:960px) {
    .banner-edge-imgleft .fl-col:first-child {
        width: 100% !important;
        max-width: 100%
    }
}

.banner-edge-imgleft .fl-col-group {}

@media (max-width:960px) {
    .banner-edge-imgleft .fl-col-group {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%
    }
}

.banner-edge-imgleft .fl-col:first-child {}

@media (max-width:960px) {
    .banner-edge-imgleft .fl-col:first-child {
        -webkit-flex-basis: 100%;
        -ms-flex: 0 1 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }
}

.banner-edge-imgleft .fl-col:nth-child(2) {}

@media (max-width:960px) {
    .banner-edge-imgleft .fl-col:nth-child(2) {
        -webkit-flex-basis: 100%;
        -ms-flex: 0 1 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }
}

.page-sla .banner .fl-col:first-child img {
    margin-top: 196px
}

.page-cp .banner .fl-col:first-child img {
    margin-top: 115px
}

.page-cp .banner,
.page-sla .banner,
.page-network .banner {
    min-height: 500px
}

.page-cp .banner .fl-row-content-wrap,
.page-sla .banner .fl-row-content-wrap,
.page-network .banner .fl-row-content-wrap {
    min-height: 500px
}

@media only screen and (max-width:1150px) {

    .page-cp .banner .fl-col,
    .page-sla .banner .fl-col,
    .page-network .banner .fl-col {
        width: 100% !important
    }

    .page-cp .banner .fl-col:first-child img,
    .page-sla .banner .fl-col:first-child img,
    .page-network .banner .fl-col:first-child img {
        margin-top: 0
    }

    .page-cp .banner .fl-col-group,
    .page-sla .banner .fl-col-group,
    .page-network .banner .fl-col-group {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%
    }

    .page-cp .banner .fl-col:first-child,
    .page-sla .banner .fl-col:first-child,
    .page-network .banner .fl-col:first-child {
        -webkit-flex-basis: 100%;
        -ms-flex: 0 1 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .page-cp .banner .fl-col:nth-child(2),
    .page-sla .banner .fl-col:nth-child(2),
    .page-network .banner .fl-col:nth-child(2) {
        -webkit-flex-basis: 100%;
        -ms-flex: 0 1 100%;
        flex-basis: 100%;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .page-cp .banner .fl-col:nth-child(3),
    .page-sla .banner .fl-col:nth-child(3),
    .page-network .banner .fl-col:nth-child(3) {
        display: none
    }
}

@media (min-width:1150px) {
    .page-sla .banner img {
        margin-top: 196px
    }
}

.bee-surf img,
.bee-parachute img {
    max-height: 380px
}

.banner-story {
    min-height: 600px;
    background: #2bb9e0
}

.banner-story h1,
.banner-story h4,
.banner-story p {
    color: #fff
}

.banner-story img {
    margin-bottom: 50px
}

@media (max-width:800px) {
    .banner-story img {
        max-height: 250px
    }
}

@media (max-width:667px) {
    .banner-story img {
        max-height: 180px;
        margin-bottom: 20px
    }
}

.banner-story .fl-row-content-wrap {
    min-height: 600px
}

@media (max-width:960px) {
    .banner-story .fl-col:first-child {
        width: 90%
    }

    .banner-story .fl-col:nth-child(2) {
        display: none
    }
}

.banner-story .fl-col:first-child {
    padding-left: 8%
}

@media (max-width:800px) {
    .banner-story .fl-col:first-child {
        padding-left: 0
    }
}

.page-network .banner-edge-imgleft .fl-row-content-wrap {
    padding-top: 0
}

.page-network .banner {
    min-height: auto
}

.page-network .banner .fl-photo-align-left img {
    max-height: 280px
}

.page-network .banner .fl-col:first-child img {
    margin-top: 176px !important
}

.page-network .banner .fl-col:nth-child(2) {
    padding-top: 100px
}

@media only screen and (max-width:1150px) {
    .page-network .banner .fl-col:nth-child(2) {
        padding-top: 40px
    }
}

.page-network .banner .fl-row-content-wrap {
    min-height: auto
}

@media (min-width:960px) {
    .page-network .banner {
        background: url(assets/images/banner-gradient.png);
        background-repeat-y: repeat
    }
}

@media only screen and (max-width:1150px) {
    .page-network .banner .fl-col:first-child img {
        margin-top: 40px !important
    }
}

.banner-network .banner-spacer-top {
    display: none !important
}

.banner-network h4 {
    margin-bottom: 8px
}

.banner-network img {
    margin-top: 0;
    padding-top: 0
}

.banner-network .fl-col:nth-child(2) .fl-col-content {}

@media (min-width:800px) {
    .banner-network .fl-col:nth-child(2) .fl-col-content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.banner-network .fl-col:nth-child(3) {}

@media (max-width:800px) {
    .banner-network .fl-col:nth-child(3) {
        display: none
    }
}

.hex-icon {
    width: 22px;
    height: 22px;
    margin-right: 7px;
    position: relative;
    top: 5px
}

.fl-builder-full .site-inner {
    width: 100%;
    padding-top: 0
}

@media only screen and (max-width:800px) {
    .fl-builder-full .site-inner {
        padding-left: 0;
        padding-right: 0
    }
}

.pad-small {
    padding: 30px 0
}

@media only screen and (max-width:800px) {
    .fl-builder-full .site-inner {
        padding-left: 0;
        padding-right: 0
    }
}

@media (max-width:800px) {
    .fl-col-small {
        max-width: none !important
    }
}

a {
    text-decoration: none
}

.heading-bold h3,
.heading-bold h4 {
    text-align: left;
    color: #090909;
    font-family: museosans900
}

.heading-intro h3,
.heading-intro h2 {
    font-family: museosans900;
    letter-spacing: .25px;
    text-transform: uppercase;
    font-size: 29px
}

.paragraph-bold p {
    font-family: museosans500;
    color: #272E2A;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 13px
}

.fl-module-content ul {
    font-family: museosans500;
    margin-bottom: 20px
}

.fl-module-content ul li:before {
    font-family: FontAwesome;
    content: "\f0c8";
    display: inline-block;
    padding-right: 6px;
    color: #655143;
    font-size: 13px;
    position: relative;
    bottom: 1px
}

.fl-module-content ul.white-list li {
    color: #fff
}

.fl-module-content ul.white-list li:before {
    color: #fff
}

.fl-module-content ol {
    font-family: museosans500;
    list-style-position: inside
}

.fl-module-content ol li {
    list-style-type: decimal
}

.separator {
    text-align: center;
    padding: 12px 0
}

.separator .fl-module-content {
    margin: 0
}

.separator h3,
.separator h2 {
    color: #fff;
    margin: 0;
    font-family: museosans700;
    font-size: 36px
}

@media (max-width:480px) {

    .separator h3,
    .separator h2 {
        font-size: 30px;
        padding: 0 10px
    }
}

.orange {
    background: #f3a01a
}

.blue {
    background: #00A4DE
}

.babyblue {
    background: #8dc9dd
}

.lightblue-row {
    background: #e8f0ef
}

.icon-row .fl-row-content-wrap {
    padding: 54px 0
}

.icon-row .fl-row-fixed-width {
    max-width: 1020px
}

.icon-row img {
    max-height: 210px
}

.icon-row .fl-col .fl-rich-text {
    padding-top: 5%
}

.icon-row-sla img {
    max-height: 400px;
    padding-top: 5%
}

.icon-row-sla .fl-col .fl-rich-text {
    padding-top: 0
}

.row-staff .fl-col-group {}

@media (max-width:960px) {
    .row-staff .fl-col-group {
        display: -webkit-flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column-reverse;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }
}

.row-staff .fl-col-group .fl-col {}

@media (max-width:960px) {
    .row-staff .fl-col-group .fl-col {
        width: 100% !important
    }
}

.row-staff .fl-col:first-child .fl-module-content {
    margin: 0
}

.row-staff .fl-col:nth-child(2) {
    padding: 50px 0
}

.row-staff img {
    max-height: 350px;
    margin-top: 37px
}

@media (max-width:800px) {
    .row-staff img {
        margin-top: 0;
        top: 1px;
        position: relative
    }
}

.row-exchange h3,
.row-exchange h4 {
    color: #fff
}

.row-exchange ul li {
    color: #fff;
    text-indent: -15px;
    padding-left: 15px;
    font-size: 15px;
    margin-bottom: 5px
}

.row-exchange .fl-module-content ul li:before {
    content: "";
    width: 15px;
    background: url(assets/images/icon-globe-w.png);
    height: 15px;
    position: relative;
    background-size: cover;
    display: inline-block;
    top: 2px;
    right: 5px;
    margin-left: 5px
}

.row-netfeatures li {}

@media (min-width:800px) {
    .row-netfeatures li {
        line-height: 48px
    }
}

.row-netfeatures .fl-module-content ul li:before {
    content: "";
    width: 18px;
    background: url(assets/images/icon-globe.png);
    height: 20px;
    position: relative;
    background-size: cover;
    display: inline-block;
    top: 4px;
    right: 6px;
    margin-left: 5px
}

.aligncenter .fl-html {
    text-align: center
}

.button,
button.tt_form_button {
    border-radius: 15px / 15px;
    display: inline-block
}

.button a,
button.tt_form_button a {
    color: #fff
}

.button.orange:hover,
button.tt_form_button.orange:hover {
    background: #f29e07
}

.button.blue,
button.tt_form_button.blue {
    background-color: #0391d6
}

.button.blue:hover,
button.tt_form_button.blue:hover {
    background: #0380bd
}

.btn-small {
    padding: 5px 18px;
    font-size: 14px;
    font-family: museosans500
}

.btn-medium {
    padding: 10px 29px;
    font-size: 16px;
    font-family: museosans700
}

.btn-large {
    font-family: museosans900;
    font-size: 17px;
    padding: 12px 62px
}

.btn-header {
    font-family: museosans900;
    padding: 12px 62px;
    font-size: 21px;
    margin-bottom: 20px
}

@media (max-width:480px) {
    .btn-header {
        font-size: 16px;
        padding: 12px 27px
    }
}

.btn-top {
    margin-top: -20px
}

.wpcf7 input[type=submit],
.wpcf7 .button {
    padding: 12px 62px !important
}

.btn-text {
    font-family: museosans500;
    font-size: 16px;
    display: inline-block
}

.btn-text a {
    color: #00A4DE
}

.btn-reviews {
    margin-right: 10px
}

.btn-reviews a {
    color: #655143
}

.button-separator,
.text-separator {
    background-image: url(assets/images/butto-sep.png);
    background-repeat-y: no-repeat;
    background-repeat-x: repeat;
    background-position: center;
    background-repeat: repeat-x;
    text-align: center;
    margin: 40px 0
}

.button-separator .button,
.text-separator .button {
    margin-right: 0
}

.text-separator h2,
.text-separator h4,
.text-separator h3 {
    text-align: center;
    display: inline-block;
    background: #fff;
    margin: 0;
    padding: 0 15px
}

.table-normal {
    background: #e8f0ef;
    text-align: center;
    border-radius: 14px / 14px;
    padding: 0 10px;
    margin: 11px
}

@media (max-width:800px) {
    .table-normal {
        max-width: 300px;
        margin: 20px auto
    }
}

.table-normal ul {
    margin: 0
}

.table-text {
    padding: 20px 0
}

.table-text p {
    margin: 0
}

.our-story .table-normal {
    min-height: 268px
}

.our-story .table-text {
    padding: 30px 21px
}

@media (max-width:1200px) {
    .our-story .table-text {
        padding: 30px 0
    }

    .our-story .table-text p {
        font-size: 16px
    }
}

.our-story .table-text img {
    margin-bottom: 10px
}

.our-story .intro-row .fl-col:last-child .fl-module-content {
    margin: 0
}

.table-title {
    background: #E8F0EF url(assets/images/title-comb-bg.png) no-repeat scroll 0 0/100% auto;
    margin: 0 -10px;
    border-radius: 14px 14px 0 0;
    padding: 30px 0;
    background-position: bottom;
    background-size: cover
}

.table-title h4 {
    color: #fff;
    font-family: museosans900;
    margin: 0;
    text-transform: uppercase
}

.table-pricing {
    max-width: 460px;
    text-align: center;
    background-color: #e8f0ef;
    padding: 0 10px;
    margin: 11px auto;
    border-radius: 14px / 14px
}

.table-pricing .table-pricing-title {
    background: #E8F0EF url(assets/images/title-comb-bgt.png) no-repeat scroll 0 0/100% auto;
    background-position: bottom;
    border-radius: 14px 14px 0 0;
    margin: 0 -10px;
    text-align: center;
    background-color: #F9B233;
    padding: 30px 0
}

.table-pricing .table-pricing-title h4 {
    font-family: museosans900;
    color: #fff;
    text-transform: uppercase;
    margin: 0
}

.table-pricing ul {
    display: inline-block;
    text-align: left;
    padding: 25px 0;
    margin-bottom: 0
}

.table-pricing ul li {
    font-family: museosans500;
    font-size: 20px
}

.table-pricing ul li:nth-child(1):before {
    content: "";
    width: 20px;
    background: url(assets/images/icon-cpu.png);
    height: 19px;
    position: relative;
    background-size: contain;
    display: inline-block;
    top: 3px;
    right: 4px
}

.table-pricing ul li:nth-child(2):before {
    content: "";
    width: 20px;
    background: url(assets/images/icon-ram.png);
    height: 19px;
    position: relative;
    background-size: contain;
    display: inline-block;
    top: 3px;
    right: 4px
}

.table-pricing ul li:nth-child(3):before {
    content: "";
    width: 20px;
    background: url(assets/images/icon-hdd.png);
    height: 19px;
    position: relative;
    background-size: contain;
    display: inline-block;
    top: 3px;
    right: 4px
}

.table-pricing ul li:nth-child(4):before {
    content: "";
    width: 20px;
    background: url(assets/images/icon-gpu.png);
    height: 19px;
    position: relative;
    background-size: contain;
    display: inline-block;
    top: 3px;
    right: 4px
}

.table-pricing p {
    color: #00CE3C;
    margin-bottom: 5px
}

.table-pricing .table-pricing-price {
    padding: 10px 0
}

.table-pricing .table-pricing-price p,
.table-pricing .table-pricing-price h3,
.table-pricing .table-pricing-price h5 {
    display: inline-block
}

.table-pricing .table-pricing-price p {
    font-size: 29px;
    font-family: MuseoSans300;
    color: #352923
}

.table-pricing .table-pricing-price h3 {
    font-size: 45px;
    font-family: MuseoSans900;
    color: #f3a01a
}

.table-pricing .button {
    width: 88%;
    margin-bottom: 25px
}

.site-footer,
.footer-widgets {
    background-color: #524034;
    color: #fff;
    padding-top: 50px
}

.site-footer li,
.footer-widgets li {
    line-height: 30px
}

.site-footer p,
.site-footer a,
.footer-widgets p,
.footer-widgets a {
    color: #fff;
    font-size: 15px
}

.site-footer h3,
.footer-widgets h3 {
    color: #fff;
    font-size: 21px;
    font-family: MuseoSans700
}

@media (max-width:960px) {

    .site-footer h3,
    .footer-widgets h3 {
        margin-top: 20px
    }
}

.footer-widgets .wrap {}

@media (max-width:960px) {
    .footer-widgets .wrap {
        max-width: 735px
    }
}

@media (max-width:568px) {
    .footer-widgets .wrap {
        max-width: 405px
    }
}

@media (max-width:375px) {
    .footer-widgets .wrap {
        max-width: 340px
    }
}

.footer-widgets-1,
.footer-widgets-2,
.footer-widgets-3,
.footer-widgets-4 {
    width: 22%;
    float: left
}

@media (max-width:960px) {

    .footer-widgets-1,
    .footer-widgets-2,
    .footer-widgets-3,
    .footer-widgets-4 {
        width: 47.5%
    }
}

@media (max-width:568px) {

    .footer-widgets-1,
    .footer-widgets-2,
    .footer-widgets-3,
    .footer-widgets-4 {
        width: 100%
    }
}

.footer-widgets-1,
.footer-widgets-2,
.footer-widgets-3 {
    margin-right: 4%
}

@media (max-width:568px) {

    .footer-widgets-1,
    .footer-widgets-2,
    .footer-widgets-3 {
        margin-right: 0
    }
}

.footer-widgets-1,
.footer-widgets-3 {}

@media (max-width:960px) {

    .footer-widgets-1,
    .footer-widgets-3 {
        margin-right: 5%
    }
}

.footer-widgets-2,
.footer-widgets-4 {}

@media (max-width:960px) {

    .footer-widgets-2,
    .footer-widgets-4 {
        margin-right: 0
    }
}

.beneath-bottom h4 {
    font-size: 16px;
    text-transform: initial;
    margin-bottom: 20px;
    margin-top: 0;
    font-family: MuseoSans900;
    color: #fff
}

.beneath-bottom .social-item {
    margin-right: 20px
}

.beneath-bottom .social-item:last-child:after {
    margin-right: 0
}

@media (max-width:320px) {
    .beneath-bottom .social-item {
        margin-right: 10px;
        max-width: 29px
    }
}

.beneath-bottom #mc4wp-form-1 {
    max-width: 385px;
    margin: auto
}

@media (max-width:480px) {
    .beneath-bottom #mc4wp-form-1 {
        max-width: 344px
    }
}

@media (max-width:375px) {
    .beneath-bottom #mc4wp-form-1 {
        max-width: 284px
    }
}

@media (max-width:375px) {
    .beneath-bottom #mc4wp-form-1 {
        max-width: 264px
    }
}

.beneath-bottom #mc4wp_email {
    max-width: 245px;
    border-radius: 14px 0 0 14px;
    background: #90694D;
    border: 0;
    color: #524034 !important;
    height: 39px;
    font-family: museosans500;
    font-size: 14px;
    float: left;
    padding: 12px
}

@media (max-width:480px) {
    .beneath-bottom #mc4wp_email {
        max-width: 60%
    }
}

@media (max-width:320px) {
    .beneath-bottom #mc4wp_email {
        max-width: 50%;
        font-size: 12px
    }
}

.beneath-bottom #mc4wp_email.nb-nl {}

@media (max-width:480px) {
    .beneath-bottom #mc4wp_email.nb-nl {
        max-width: 52%
    }
}

@media (max-width:375px) {
    .beneath-bottom #mc4wp_email.nb-nl {
        max-width: 50%;
        font-size: 12px
    }
}

.beneath-bottom input:nth-child(2) {
    float: left;
    border-radius: 14px;
    position: relative;
    right: 10px;
    background: #F9B233;
    border: 0;
    height: 39px;
    font-family: museosans700;
    font-size: 17px;
    text-transform: none;
    line-height: 0
}

@media (max-width:375px) {
    .beneath-bottom input:nth-child(2) {
        font-size: 13px
    }
}

.beneath-bottom .one-half {}

@media (max-width:800px) {
    .beneath-bottom .one-half {
        margin-bottom: 20px
    }
}

.footer-contact {
    background-color: #F9B233;
    text-align: center;
    padding: 28px 0 18px;
    color: #fff;
    font-size: 18px;
    font-family: museosans700;
    background: url(assets/images/footer-top-bee.png) #f3a01a !important;
    background-attachment: fixed
}

.footer-contact .footer-widgets-1 {
    font-size: 23px;
    font-family: museosans900;
    bottom: 5px;
    position: relative
}

.footer-contact .widget-area {}

@media (max-width:960px) {
    .footer-contact .widget-area {
        padding: 12px 0
    }
}

.footer-contact a {
    color: #fff
}

.footer-bottom {
    background-color: #47382e;
    text-align: center;
    padding: 40px 0;
    color: #fff;
    font-size: 15px
}

.footer-bottom ul li {
    display: inline-block
}

.footer-bottom ul li:after {
    content: '\007C';
    padding-left: 10px;
    color: #F9B233;
    padding-right: 10px
}

.footer-bottom ul li:last-child:after {
    content: ''
}

.footer-bottom a {
    color: #F9B233
}

.footer-bottom-main .widget_nav_menu {
    padding: 10px 0
}

.utility-bar {
    color: #878787;
    background: #e8f0ef;
    font-size: 13.5px;
    font-family: museosans900
}

.utility-bar .wrap {
    max-width: 1370px;
    padding-left: 1.7%;
    padding-right: 1.7%
}

@media (max-width:375px) {
    .utility-bar .wrap {
        padding-left: 1%;
        padding-right: 1%
    }
}

.utility-bar span {
    padding-right: 10px
}

@media (max-width:667px) {
    .utility-bar span {
        display: inline
    }
}

.utility-bar i {
    padding-right: 3px;
    color: #090909
}

.utility-bar .fa-envelope,
.utility-bar .fa-comment {
    color: #00A4DE
}

.utility-bar a {
    color: #00A4DE
}

.utility-bar a:hover {
    text-decoration: underline
}

.utility-bar-email {}

@media (max-width:800px) {
    .utility-bar-email {
        display: none !important
    }
}

.utility-bar-login {
    display: none
}

.utility-bar-left {
    width: 38%
}

@media (max-width:1200px) {
    .utility-bar-left {
        width: 38%
    }
}

@media (max-width:960px) {
    .utility-bar-left {
        width: 45%
    }
}

@media (max-width:800px) {
    .utility-bar-left {
        width: 43%
    }
}

@media (max-width:667px) {
    .utility-bar-left {
        width: 100%
    }
}

.utility-bar-mid {
    width: 50%
}

@media (max-width:1200px) {
    .utility-bar-mid {
        width: 41%
    }
}

@media (max-width:960px) {
    .utility-bar-mid {
        width: 36%
    }
}

@media (max-width:800px) {
    .utility-bar-mid {
        width: 35%
    }
}

@media (max-width:667px) {
    .utility-bar-mid {
        width: 100%
    }
}

.utility-bar-right {
    width: 12%
}

@media (max-width:1200px) {
    .utility-bar-right {
        width: 21%
    }
}

@media (max-width:960px) {
    .utility-bar-right {
        width: 16%
    }
}

@media (max-width:800px) {
    .utility-bar-right {
        width: 20%
    }
}

@media (max-width:667px) {
    .utility-bar-right {
        display: none
    }
}

.utility-bar-left,
.utility-bar-mid,
.utility-bar-right {}

@media (max-width:480px) {

    .utility-bar-left,
    .utility-bar-mid,
    .utility-bar-right {
        width: 100%;
        text-align: center;
        padding-left: 4%
    }
}

.utility-bar-left p,
.utility-bar-mid p,
.utility-bar-right p {
    margin-bottom: 0
}

.utility-bar-left,
.utility-bar-mid {
    float: left
}

.utility-bar-left,
.utility-bar-right {
    padding: 8px 0
}

@media (max-width:667px) {

    .utility-bar-left,
    .utility-bar-right {
        padding: 2px 0 0;
        text-align: center
    }
}

.utility-bar-right {
    float: right;
    text-align: right
}

#___gcse_0 {
    max-width: 500px;
    float: right
}

@media (max-width:960px) {
    #___gcse_0 {
        max-width: 233px;
        float: left
    }
}

@media (max-width:800px) {
    #___gcse_0 {
        max-width: 180px;
        float: left
    }
}

@media (max-width:667px) {
    #___gcse_0 {
        float: left;
        padding-left: 2%;
        max-width: 85%
    }
}

.breadcrumb {
    background: #eee;
    color: #090909;
    margin-bottom: 0;
    font-size: 14px;
    font-family: museosans500;
    padding: 11px 0
}

.gsc-search-button {
    max-width: 43px;
    position: relative
}

.gsc-search-button:before {
    font-family: FontAwesome;
    content: "\f002";
    display: inline-block;
    padding-right: 3px;
    color: #fff;
    position: absolute;
    z-index: 9;
    left: -4px;
    top: 3px
}

table.gsc-search-box,
form.gsc-search-box {
    margin-bottom: 0 !important
}

.gsc-input-box {
    border-radius: 8px / 8px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1)
}

input.gsc-input {
    font-size: 12px !important;
    bottom: 3px;
    position: relative;
    width: 90% !important;
    left: 5px
}

input.gsc-search-button-v2 {
    height: 25px !important;
    padding: 7px 25px !important;
    width: 60px !important;
    margin-top: 0 !important;
    top: 4px !important;
    position: relative !important;
    border-radius: 8px !important;
    right: 27px !important
}

.site-header {
    min-height: 80px
}

.nav-primary .genesis-nav-menu .menu-item-48 a,
.mini-header .genesis-nav-menu .menu-item-48 a {
    cursor: pointer
}

.nav-primary .genesis-nav-menu a,
.mini-header .genesis-nav-menu a {
    color: #090909;
    font-family: museosans500;
    font-size: 16px;
    cursor: default;
    outline: 0
}

.nav-primary .genesis-nav-menu a:hover,
.mini-header .genesis-nav-menu a:hover {
    color: #F9B233
}

.nav-primary .genesis-nav-menu .current-menu-item a,
.mini-header .genesis-nav-menu .current-menu-item a {
    color: #F9B233
}

.nav-primary {
    background-color: rgba(245, 245, 245, 0)
}

.nav-primary .genesis-nav-menu .sub-menu,
.mini-header .sub-menu {
    width: 250px;
    border: 0
}

@media (max-width:960px) {

    .nav-primary .genesis-nav-menu .sub-menu,
    .mini-header .sub-menu {
        width: 100%
    }
}

.nav-primary .genesis-nav-menu .sub-menu a,
.mini-header .sub-menu a {
    cursor: pointer;
    position: relative;
    width: 250px;
    padding: 15px 20px;
    border: 0
}

@media (max-width:960px) {

    .nav-primary .genesis-nav-menu .sub-menu a,
    .mini-header .sub-menu a {
        width: 100%
    }
}

.nav-primary .genesis-nav-menu .sub-menu a:hover,
.mini-header .sub-menu a:hover {
    background: #F9B233;
    color: #fff !important
}

.mini-header .wrap {}

@media (max-width:960px) {
    .mini-header .wrap {
        padding-left: 0;
        padding-right: 0
    }
}

.mini-header .alignleft {}

@media (max-width:960px) {
    .mini-header .alignleft {
        position: absolute;
        padding-left: 1%
    }
}

.mini-header .alignright {}

@media (max-width:960px) {
    .mini-header .alignright {
        width: 100%;
        position: relative;
        bottom: 0
    }
}

.site-header {
    background-color: #fff
}

@media (min-width:1000px) and (max-width:1200px) {
    .site-header .wrap {
        padding-left: 1%;
        padding-right: 1%
    }
}

@media (min-width:800px) and (max-width:1200px) {
    .breadcrumb .wrap {
        padding-left: 5%;
        padding-right: 5%
    }
}

.title-area {
    float: left;
    width: 18%;
    padding: 10px 0
}

.header-full-width .title-area {
    width: 100%
}

.site-title {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.2
}

.site-title a,
.site-title a:hover {
    color: #333
}

.header-image .site-title>a {
    float: left;
    width: 100%;
    min-height: 56px;
    background: url(assets/images/logo.png) no-repeat left;
    background-size: contain;
    image-rendering: auto;
    outline: 0
}

.site-description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5
}

.site-description,
.site-title {
    margin-bottom: 0
}

.header-image .site-description,
.header-image .site-title {
    display: block;
    text-indent: -9999px
}

.site-header {
    background-color: #fff
}

.site-header .wrap {}

.header-full-width .title-area {
    width: 100%
}

.site-title {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.2
}

.site-title a,
.site-title a:hover {
    color: #333
}

.site-description {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5
}

.site-description,
.site-title {
    margin-bottom: 0
}

.header-image .site-description,
.header-image .site-title {
    display: block;
    text-indent: -9999px
}

.site-header .widget-area {
    float: right;
    text-align: right;
    width: 82%
}

@media only screen and (max-width:480px) {
    .header-image .site-title>a {
        background-size: contain
    }
}

.mini-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    display: none;
    background: #fff;
    border-bottom: 1px solid #f5f5f5
}

.mini-header .wrap {}

@media (max-width:480px) {
    .mini-header .wrap {
        padding: 0 2%
    }
}

.admin-bar .mini-header {
    top: 32px
}

.mini-header .alignleft {
    margin-top: 16px
}

.mini-header .alignleft img {
    max-height: 45px
}

nav,
.menu-menu-container {
    clear: both
}

.sub-menu-toggle,
.menu-toggle {
    display: none;
    visibility: hidden
}

@media only screen and (min-width:961px) {

    nav,
    .menu-menu-container {
        display: block !important
    }
}

@media only screen and (max-width:960px) {

    .menu-toggle,
    .sub-menu-toggle {
        display: block;
        font-size: 20px;
        font-size: 2rem;
        font-weight: 700;
        overflow: hidden;
        padding: 20px;
        padding: 2rem;
        float: right;
        visibility: visible
    }

    button.menu-toggle,
    button.sub-menu-toggle {
        background-color: transparent;
        color: #999
    }

    .mini-header button.menu-toggle,
    .mini-header button.sub-menu-toggle {
        color: #F9B233
    }

    .sub-menu-toggle {
        padding: 18px;
        padding: 1.8rem;
        position: absolute;
        right: 0;
        top: 0
    }

    .menu-toggle:before {
        content: "\2261"
    }

    .menu-toggle.activated:before {
        content: "\00D7"
    }

    .sub-menu-toggle:before {
        content: "+"
    }

    .sub-menu-toggle.activated:before {
        content: "-"
    }

    nav,
    .menu-menu-container {
        display: none;
        position: relative
    }

    .genesis-nav-menu .menu-item {
        background-color: #f5f5f5;
        display: block;
        position: relative;
        text-align: left
    }

    .genesis-nav-menu .menu-item:hover {
        position: relative
    }

    .genesis-nav-menu .sub-menu {
        clear: both;
        display: none;
        opacity: 1;
        position: static;
        width: 100%
    }

    .genesis-nav-menu .sub-menu a {
        border-left: 0;
        position: relative;
        width: auto
    }

    .genesis-nav-menu .sub-menu .sub-menu {
        margin: 0
    }

    .genesis-nav-menu .sub-menu .sub-menu a {
        background-color: #f5f5f5;
        padding-left: 30px
    }

    .genesis-nav-menu .sub-menu .sub-menu .sub-menu a {
        background-color: #fff;
        padding-left: 40px
    }

    .nav-primary a:hover,
    .nav-primary .current-menu-item>a {
        color: #333
    }

    .menu-menu-container {
        min-width: 220px
    }
}

@media (max-width:960px) {

    #menu-menu li ul,
    #menu-hoofdmenu li ul {
        display: block !important;
        height: auto !important
    }

    #menu-menu li ul li,
    #menu-hoofdmenu li ul li {
        -webkit-transition: height .4s;
        transition: height .4s;
        height: 0
    }

    #menu-menu li .activated+ul li,
    #menu-hoofdmenu li .activated+ul li {
        height: 46px
    }
}

@media only screen and (max-width:960px) {
    .header-image .site-title>a {
        background-position: left top
    }

    .site-description,
    .site-header .title-area,
    .site-title {
        text-align: left;
        padding-left: 3%
    }

    .site-header .widget-area {
        width: 100%;
        position: absolute;
        z-index: 9;
        padding-top: 5px
    }

    .site-header .widget-area .wrap {
        padding: 0
    }

    .title-area {
        width: 50%
    }
}

.tt_theme_separator .tt_info-left {
    padding-right: 20px;
    min-width: 180px
}

.ttshowcase_slider .ttshowcase_rl_quote {
    text-align: left;
    font-size: 19px;
    font-weight: 700;
    line-height: 24px
}

.ttshowcase_slider .ttshowcase_rl_quote:before {
    content: url(assets/images/review-icon.png);
    position: relative;
    z-index: 100000;
    left: 0;
    top: 4px;
    float: left;
    display: block;
    height: 130px;
    width: 160px
}

@media (max-width:800px) {
    .ttshowcase_slider .ttshowcase_rl_quote:before {
        height: auto;
        width: auto;
        content: ""
    }
}

.ttshowcase_slider .ttshowcase_rl_title {
    font-size: 19px;
    font-weight: 700;
    text-align: right;
    color: grey
}

.ttshowcase_slider .ttshowcase_rl_title:before {
    content: "\2014"
}

.ttshowcase_slider .ttshowcase_rl_quote p {
    width: 87%;
    font-size: 20px
}

@media (max-width:800px) {
    .ttshowcase_slider .ttshowcase_rl_quote p {
        width: 100%;
        padding: 0 2%
    }
}

.ttshowcase_slider .bx-wrapper {
    margin: 0 auto 0 !important
}

.tt-slider-controls {
    margin-top: 0 !important
}

.tt_1cl {
    margin: 0 !important
}

.ttshowcase_rl_breed {
    padding: 40px 0 !important
}

.tt-slider-controls {
    position: relative;
    bottom: 28px
}

.fl-col-group.fl-node-571db954cec03>div>div>div>div>div>p:nth-child(3) {
    display: none
}

.home .intro-row .fl-col:first-child {
    padding: 25px 0
}

@media (min-width:1200px) {
    .home .intro-row .fl-col:first-child {
        padding: 52px 0
    }
}

@media (max-width:800px) {
    .home .intro-row .fl-col:first-child {
        padding: 30px 0
    }
}

.home .intro-row .fl-col:nth-child(2) {}

@media (max-width:1000px) {
    .home .intro-row .fl-col:nth-child(2) {
        display: none
    }
}

.home .our-story .fl-col:nth-child(2) {}

@media (min-width:960px) {
    .home .our-story .fl-col:nth-child(2) {
        padding: 70px
    }
}

@media (max-width:960px) {
    .home .our-story .fl-col:nth-child(2) {
        padding: 70px
    }
}

@media (max-width:800px) {
    .home .our-story .fl-col:nth-child(2) {
        padding: 70px 0
    }
}

@media (max-width:1000px) {
    .home .our-story .fl-col {
        width: 100% !important
    }

    .home .our-story .fl-col:first-child {
        min-height: 415px;
        max-width: 100%
    }
}

@media (max-width:1000px) and (max-width:480px) {
    .home .our-story .fl-col:first-child {
        min-height: 290px
    }
}

@media (max-width:1000px) {
    .intro-row .fl-col {
        width: 100% !important
    }
}

.row-slapoints .fl-col-group {
    min-height: 130px
}

.single-ttshowcase .tt_single_page_testimonial {
    display: none
}

.page-affiliate .intro-row img {
    max-height: 240px;
    margin: 22px 0
}

.page-affiliate .row-benefits .fl-rich-text {
    text-align: center
}

.page-affiliate .banner .fl-row-content-wrap {
    background-position: left center
}

@media (max-width:800px) {
    .page-affiliate .banner .fl-row-content-wrap {
        background-image: url(https://www.snel.com/wp-content/uploads/aff-head-mob.png) !important
    }
}

.page-affiliate .banner .fl-row-content-wrap .bee-affiliate .fl-module-content {}

@media (max-width:800px) {
    .page-affiliate .banner .fl-row-content-wrap .bee-affiliate .fl-module-content {
        margin: 0
    }
}

div.fl-accordion-item:first-child {
    display: block
}

.fl-accordion img {
    margin: 20px auto
}

.fl-accordion-button {
    font-family: museosans500;
    background: #FDE8B4;
    color: #655143
}

.fl-accordion-content {
    background: #eee
}

.fl-accordion-item-active .fl-accordion-button {
    background: #f3a01a;
    color: #fff
}

.aff-table {
    display: inline-block;
    text-align: left;
    padding: 0
}

.aff-table li {
    list-style: none;
    font-size: 23px;
    font-family: museosans700
}

.aff-table li:before {
    content: "" !important
}

code {
    color: #333;
    background: #EBEBEB;
    padding: 5px 13px;
    border-radius: 0;
    border: 1px solid #DEDBDB
}

.page-snelwallet .lightblue-row .fl-col:first-child {
    padding: 50px 0
}

.page-snelwallet .lightblue-row .fl-module-content {
    margin: 0
}

.page-snelwallet .banner .fl-row-content-wrap {
    background-position: top center
}

table.snelwallet {
    background-image: url(https://www.snel.com/wp-content/uploads/comb-trans.png);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 90%;
    line-height: 1;
    border: 0;
    max-width: 537px;
    margin: 20px auto
}

table.snelwallet tbody {
    border-bottom: 0
}

table.snelwallet>tbody>tr>td {
    font-size: 23px;
    font-family: museosans700;
    letter-spacing: 1px;
    color: #272E2A;
    border: 0
}

.page-dedicated .icon-row .fl-col .fl-rich-text {
    padding-top: 3%
}

.page-dedicated .row-teal h3,
.page-dedicated .row-teal p {
    color: #fff
}

.page-dedicated .row-teal .fl-module-content {
    margin: 0
}

.page-dedicated .row-teal .fl-col:nth-child(2) {
    padding: 70px
}

@media (max-width:960px) {
    .page-dedicated .row-teal .fl-col:nth-child(2) {
        padding: 70px 5px
    }
}

.page-dedicated .row-teal .fl-col {}

@media (max-width:1200px) {
    .page-dedicated .row-teal .fl-col {
        width: 100% !important
    }
}

.row-dedtables .fl-html {}

@media (min-width:800px) {
    .row-dedtables .fl-html {
        margin: 10px 20px
    }
}

.row-dedtables .fl-row-fixed-width {
    max-width: 1400px
}

@media (min-width:800px) and (max-width:1000px) {
    .row-dedtables {
        padding-bottom: 60px
    }
}

.row-dedtables .fl-col {}

@media (max-width:1200px) {
    .row-dedtables .fl-col {
        width: 100% !important
    }
}

.page-reseller .table-title {
    padding: 12px 0 !important
}

.page-reseller .pricing-features {
    margin: 11px 0 24px !important
}

.page-reseller .table-title li {
    font-size: 20px !important
}

.row-orange {
    background: #f3a01a
}

.row-orange h2,
.row-orange h3,
.row-orange p,
.row-orange h4 {
    color: #fff
}

.row-orange img {}

@media (min-width:800px) {
    .row-orange img {
        float: left
    }
}

.row-orange .fl-photo-align-center {}

@media (min-width:800px) {
    .row-orange .fl-photo-align-center {
        text-align: left
    }
}

.row-orange .fl-rich-text {
    padding-right: 18px
}

.tables-reseller {
    text-align: center
}

.tables-reseller .fl-module-content ul {
    display: inline-block;
    text-align: left
}

.tables-reseller .fl-module-content ul li:before {
    content: ""
}

.tables-reseller .pricing-number-txt {
    color: #00CE3C;
    font-family: museosans900
}

@media (min-width:800px) and (max-width:1000px) {
    .tables-reseller {
        padding-bottom: 60px
    }
}

.tables-reseller .fl-col {}

@media (max-width:960px) {
    .tables-reseller .fl-col {
        width: 100% !important;
        max-width: 400px;
        float: none;
        margin: auto
    }
}

.row-photos .fl-module-content {
    margin: 0
}

.row-photos .fl-col-small {}

@media (max-width:800px) {
    .row-photos .fl-col-small {
        max-width: 100%
    }
}

.row-dataintro .fl-row-fixed-width {}

@media (min-width:800px) {
    .row-dataintro .fl-row-fixed-width {
        max-width: 1170px;
        padding-left: 7%;
        padding-right: 7%
    }
}

.row-features .fl-col:first-child .fl-col-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.row-features .fl-module-content ul li:before {
    content: ""
}

@-webkit-keyframes zooming {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes zooming {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8)
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

@media (max-width:1060px) {
    .row-features .fl-col {
        width: 100% !important
    }
}

.row-features .circle-slide {
    position: relative;
    width: 550px;
    max-width: 100%;
    margin: 60px auto
}

@media (max-width:480px) {
    .row-features .circle-slide {
        min-height: 800px !important
    }

    .row-features .circle-slide p {
        font-size: 15px
    }
}

.row-features img.holder {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: auto
}

.row-features .circle-slide ul {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    border-radius: 1000px
}

.row-features .circle-slide li>p {
    background: #efefef;
    width: 100px;
    height: 100px;
    border-radius: 500px;
    margin: 0 20px 0 0
}

.row-features .circle-slide li img {
    width: 50%;
    height: 50%;
    margin: 25%
}

@media (min-width:601px) {
    .row-features .circle-slide ul {
        position: absolute;
        width: 90%;
        height: 90%;
        border: 3px dashed #e9e9e9;
        left: 5%;
        top: 5%
    }

    .row-features .circle-slide li {
        position: absolute;
        width: 100%;
        height: 100%
    }

    .row-features .circle-slide li:before {
        content: ""
    }

    .row-features .circle-slide li>div {
        position: absolute;
        width: 60%;
        height: 60%;
        left: 20%;
        top: 20%;
        vertical-align: middle;
        display: table;
        background: #f0faf0;
        border-radius: 500px;
        -webkit-transition: -webkit-transform .4s;
        transition: transform .4s;
        -webkit-transform: rotateY(90deg);
        -ms-transform: rotateY(90deg);
        transform: rotateY(90deg)
    }

    .row-features .circle-slide p.active {
        -webkit-animation: zooming 1s infinite;
        animation: zooming 1s infinite
    }

    .row-features .circle-slide div.active {
        -webkit-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }

    .row-features .circle-slide div a {
        display: table-cell;
        vertical-align: middle;
        padding: 15px
    }

    .row-features .circle-slide h4 {
        margin: 0;
        font-size: 24px
    }

    .row-features .circle-slide li>p {
        position: absolute;
        top: 0;
        left: 50%;
        margin: -50px 0 0 -50px;
        z-index: 2
    }

    .row-features .circle-slide li:nth-child(2)>p {
        top: 50%;
        left: 100%
    }

    .row-features .circle-slide li:nth-child(3)>p {
        top: 100%;
        left: 50%
    }

    .row-features .circle-slide li:last-child>p {
        top: 50%;
        left: 0
    }
}

@media (max-width:600px) {
    .row-features .circle-slide {
        min-height: 700px !important
    }

    .row-features .circle-slide li {
        margin-bottom: 15px;
        list-style: none
    }

    .row-features .circle-slide li:after {
        content: "";
        display: block;
        clear: both
    }

    .row-features .circle-slide li>p,
    .row-features .circle-slide li>div {
        float: left;
        max-width: 70%;
        max-width: -webkit-calc(100% - 121px);
        max-width: calc(100% - 121px)
    }

    .row-features .circle-slide li>div {
        text-align: left;
        background-color: #f0faf0;
        border-radius: 4px
    }

    .row-features .circle-slide div h4,
    .row-features .circle-slide div p {
        padding: 10px;
        margin: 0
    }
}

.row-eco .fl-col:nth-child(2) .fl-col-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.row-eco img {
    max-height: 410px
}

.blog .entry {
    padding: 0 50px 0 0
}

.blog .entry-content {
    font-family: museosans500
}

.blog .entry-footer .entry-meta {
    border-top: 2px solid #f5f5f5;
    padding-top: 10px;
    margin-top: 15px
}

.single-post .entry {
    padding: 0 50px 60px
}

@media (max-width:960px) {
    .single-post .entry {
        padding: 0
    }
}

.single-post .entry-content {
    margin-bottom: 40px
}

.single-post .entry-meta {
    padding: 10px;
    background: #f7b02f;
    border-radius: 0 0 6px 6px;
    background-image: url(assets/images/comb.png);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 14%;
    margin-bottom: 23px;
    border-top: 0;
    position: relative
}

@media (max-width:667px) {
    .single-post .entry-meta {
        background-image: none
    }
}

.single-post .entry-meta a {
    color: #655143
}

.single-post .entry-meta:after {
    content: "";
    background: url(https://www.snel.com/wp-content/uploads/mascotte-arms.png) top;
    top: -12px;
    right: 25px;
    position: absolute;
    width: 38px;
    height: 69px;
    z-index: 1;
    background-size: cover
}

@media (max-width:667px) {
    .single-post .entry-meta:after {
        background: 0
    }
}

.sidebar .widget {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 0
}

.widget_archive li {
    border-bottom: 0;
    margin-bottom: 5px;
    padding-bottom: 0
}

.widget_archive li a {
    font-family: museosans700
}

.error-search {
    width: 99%;
    padding: 30px 25px;
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(216, 216, 216, .46);
    margin-bottom: 20px
}

.search-form .search-field {
    border: 1px solid #ddd;
    padding: 8px;
    width: 73%;
    background-color: transparent;
    font-weight: 400;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 20px auto
}

@media (max-width:667px) {
    .search-form .search-field {
        width: 80%
    }
}

.error-search>form>input {
    background: #f3a01a;
    height: 38px !important;
    text-indent: 0 !important;
    color: #fff !important;
    min-width: 110px;
    border-radius: 0 5px 5px 0;
    line-height: 0;
    position: relative;
    bottom: 1px;
    right: 6px;
    font-family: museosans900
}

@media (max-width:375px) {
    .error-search>form>input {
        min-width: 70px
    }
}

.error404 .site-inner {
    padding: 90px 0;
    text-align: center
}

.error404 h1 {
    font-size: 125px
}

.error404 h2 {
    font-family: MuseoSans900;
    font-size: 25px
}

.error404 h3 {
    font-size: 25px
}

.error404 .gs-title {
    text-align: left
}

.fl-tabs {
    text-align: center
}

.fl-tabs ul li:before {
    content: ""
}

.fl-tabs p {
    display: none
}

.fl-tabs .fl-tabs-panels {
    border-width: 0 !important
}

.fl-tabs .fl-tabs-panel-content {
    bottom: 17px;
    position: relative
}

.fl-tabs .fl-tabs-panel {
    max-width: 800px;
    margin: auto
}

@media (max-width:800px) {
    .fl-tabs .fl-tabs-panel {
        border-bottom-width: 0
    }

    .fl-tabs .fl-tabs-panel .fl-tabs-label {
        display: none
    }
}

.fl-tabs .fl-tabs-labels {
    text-transform: uppercase;
    display: inline-block
}

@media (max-width:800px) {
    .fl-tabs .fl-tabs-labels {
        margin-bottom: 40px
    }
}

.fl-tabs .fl-tabs-label {
    border-width: 0 !important;
    padding: 20px 40px !important;
    margin: 0 20px;
    background: #E8F0EF url(assets/images/title-comb-bg.png) no-repeat scroll 0 0/100% auto;
    background-position: bottom;
    background-size: cover;
    border-radius: 15px / 15px
}

@media (max-width:960px) {
    .fl-tabs .fl-tabs-label {
        border-width: 0 !important;
        padding: 15px 38px !important;
        font-size: 15px;
        margin: 10px 11px
    }
}

@media (max-width:800px) {
    .fl-tabs .fl-tabs-label {
        float: left;
        border-width: 0 !important;
        padding: 12px 28px !important;
        font-size: 13px;
        margin: 10px 6px
    }
}

@media (max-width:568px) {
    .fl-tabs .fl-tabs-label {
        float: none;
        border-width: 0 !important;
        padding: 12px 28px !important;
        font-size: 13px;
        margin: 10px 6px;
        width: 46.5%
    }
}

@media (max-width:375px) {
    .fl-tabs .fl-tabs-label {
        width: 100%
    }
}

.fl-tabs .fl-tabs-label.fl-tab-active {
    background: #f3a01a !important
}

.fl-tabs .fl-tabs-label {
    color: #fff;
    font-family: museosans900
}

.fl-tabs-horizontal .fl-tabs-label {}

@media (max-width:800px) {
    .fl-tabs-horizontal .fl-tabs-label {
        float: left !important
    }
}

@media (max-width:375px) {
    .fl-tabs-horizontal .fl-tabs-label {
        float: none !important
    }
}

.banner-spacer-top,
.banner-spacer-bottom {
    height: 60px
}

.banner-spacer-mid {
    height: 40px
}

.row-map .fl-module-content {
    margin: 0
}

.wp-image-10903 {
    max-height: 350px
}

.center-list {
    text-align: center
}

.center-list ul {
    display: inline-block;
    text-align: left
}

.responsive-table {
    width: 100%;
    margin-bottom: 1.5em
}

.responsive-table thead {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden
}

.responsive-table thead th {
    background-color: #E8F0EF;
    border: 1px solid #D3DCDB;
    font-weight: 700;
    text-align: center;
    font-family: MuseoSans900;
    color: #655143
}

.responsive-table thead th:first-of-type {
    text-align: left
}

.responsive-table th.unmetered {
    background-color: #8DC9DD;
    color: #fff
}

.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
    display: block;
    padding: 0;
    text-align: center;
    white-space: normal;
    font-size: 15px
}

@media (max-width:1200px) {

    .responsive-table tbody,
    .responsive-table tr,
    .responsive-table th,
    .responsive-table td {
        font-size: 13px
    }
}

.responsive-table th,
.responsive-table td {
    padding: .5em;
    vertical-align: middle
}

.responsive-table caption {
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: 700;
    text-align: center
}

.responsive-table tfoot {
    font-size: .8em;
    font-style: italic
}

.responsive-table tbody tr {
    margin-bottom: 1em;
    border: 2px solid #D3DCDB
}

.responsive-table tbody tr:last-of-type {
    margin-bottom: 0
}

.responsive-table tbody th[scope=row] {
    background-color: #E8F0EF;
    color: #655143;
    font-family: MuseoSans900
}

.responsive-table tbody td[data-type=currency] {
    text-align: right
}

.responsive-table tbody td[data-type=button] {
    text-align: center
}

.responsive-table tbody td[data-title]:before {
    content: attr(data-title);
    float: left;
    font-size: .8em;
    color: rgba(94, 93, 82, .75)
}

.responsive-table tbody td {
    text-align: right;
    border-bottom: 1px solid #D3DCDB
}

@media (min-width:75em) {

    .responsive-table th,
    .responsive-table td {
        padding: .75em
    }
}

@media (min-width:62em) {
    .responsive-table {
        font-size: 1em
    }

    .responsive-table th,
    .responsive-table td {
        padding: .75em .5em
    }

    .responsive-table tfoot {
        font-size: .9em
    }
}

@media (min-width:52em) {
    .responsive-table tbody td[data-type=currency] {
        text-align: right
    }

    .responsive-table tbody td[data-type=button] {
        text-align: center
    }

    .responsive-table {
        font-size: .9em
    }

    .responsive-table thead {
        position: relative;
        clip: auto;
        height: auto;
        width: auto;
        overflow: auto
    }

    .responsive-table tr {
        display: table-row
    }

    .responsive-table th,
    .responsive-table td {
        display: table-cell;
        padding: .5em
    }
}

@media (min-width:52em) and (max-width:1200px) {

    .responsive-table th,
    .responsive-table td {
        padding: .3em
    }
}

@media (min-width:52em) {
    .responsive-table caption {
        font-size: 1.5em
    }

    .responsive-table tbody {
        display: table-row-group
    }

    .responsive-table tbody tr {
        display: table-row;
        border-width: 1px
    }

    .responsive-table tbody tr:nth-of-type(even) {
        background-color: rgba(249, 177, 52, .5)
    }

    .responsive-table tbody tr:nth-of-type(odd) {
        background-color: rgba(240, 240, 240, .1)
    }

    .responsive-table tbody th[scope=row] {
        background-color: transparent;
        color: #655143;
        text-align: left
    }

    .responsive-table tbody td {
        text-align: center;
        color: #655143
    }

    .responsive-table tbody td[data-title]:before {
        content: none
    }
}

.rck-compare-table {
    width: 100%;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background-color: #fff;
    font-size: 13.5px
}

.rck-compare-table a {
    color: #00A4DE
}

.rck-compare-table tr td,
.rck-compare-table tr th {
    border-bottom: 1px solid #ddd;
    text-align: center;
    padding: 20px 0
}

.rck-compare-table tr td:first-child,
.rck-compare-table tr th:first-child {
    text-align: left;
    padding-left: 25px;
    width: 28%
}

.rck-compare-table tr td.old,
.rck-compare-table tr th.old {
    background-color: #f5f5f5
}

.rck-compare-table tr td [class^=racks-icon-]:before,
.rck-compare-table tr th [class^=racks-icon-]:before,
.rck-compare-table tr td [class*=" racks-icon-"]:before,
.rck-compare-table tr th [class*=" racks-icon-"]:before {
    color: #54b300
}

.rck-compare-table tr th {
    text-transform: uppercase;
    font-size: 13.5px;
    font-family: museosans500
}

.rck-compare-table.compare-col-5 th,
.rck-compare-table.compare-col-5 td {
    width: 18%
}

.rck-compare-table.compare-col-5 th:first-child,
.rck-compare-table.compare-col-5 td:first-child {
    width: 28%
}

.rck-compare-table.compare-col-3 th,
.rck-compare-table.compare-col-3 td {
    width: 36%
}

.rck-compare-table.compare-col-3 th:first-child,
.rck-compare-table.compare-col-3 td:first-child {
    width: 28%
}

.rck-compare-table.compare-col-4 th,
.rck-compare-table.compare-col-4 td {
    width: 24%
}

.rck-compare-table.compare-col-4 th:first-child,
.rck-compare-table.compare-col-4 td:first-child {
    width: 28%
}

.rck-compare-table.compare-col-6 th,
.rck-compare-table.compare-col-6 td {
    width: 14%
}

.rck-compare-table.compare-col-6 th:first-child,
.rck-compare-table.compare-col-6 td:first-child {
    width: 30%
}

.rck-compare-table.compare-col-7 th,
.rck-compare-table.compare-col-7 td {
    width: 12%
}

.rck-compare-table.compare-col-7 th:first-child,
.rck-compare-table.compare-col-7 td:first-child {
    width: 28%
}

.rck-compare-table.compare-col-8 th,
.rck-compare-table.compare-col-8 td {
    width: 10%
}

.rck-compare-table.compare-col-8 th:first-child,
.rck-compare-table.compare-col-8 td:first-child {
    width: 30%
}

.custom-color-scheme .rck-compare-table {
    border-top: 0
}

table.rck-compare-table-sfalti {
    background-color: #E8F0EF
}

.maintenance-sla-tekst {
    margin: 43px -58px 0 58px
}

.maintenance-sla-icon {
    margin: 0 0 0 103px
}

.sla-discount-icon {
    margin: 47px 0 0 37px
}

.sla-discount-tekst {
    margin: 0 40px 0 -112px
}

.aau-tekst {
    margin: 0 -58px 0 58px
}

.aau-icon {
    margin: 0 0 0 123px
}

.btn-payns {
    margin: 50px 0 -30px -100px
}

.btn-fx-payns {
    margin: 0
}

.table-sla {
    background-color: #e8f0ef
}

.sla-table-payns {
    padding: 39px 0;
    padding: 39px 0;
    text-align: center;
    font-size: 34px
}

span.price {
    color: #655143;
    font-family: MuseoSans700
}

.resp-icon {
    margin: -39px 0 0 100px
}

.response-time {
    text-align: center;
    font-size: 22px;
    font-family: MuseoSans300;
    color: #655143;
    padding-top: 10px
}

.response-fx-time {
    font-size: 34px;
    font-weight: 700;
    color: #655143
}

.price-fx {
    font-size: 27px;
    font-weight: 700;
    color: #655143
}

.fixed-price-table-icon img {
    float: left;
    padding-left: 25%;
    padding-right: 15px
}

.fx-title-table {
    font-size: 18px;
    font-family: MuseoSans900;
    padding: 30px 0 0;
    color: #655143
}

.price-button-fx {
    padding-bottom: 28px;
    color: #655143;
    font-size: 21px;
    font-family: museosans900
}

.fx-subtitle-table {
    font-size: 18px;
    color: #655143
}

.my-payn-table-title {
    font-size: 19px;
    font-weight: 700;
    color: #0193d7
}

.hour-sla {
    margin: 0 0 0 161px;
    font-size: 49px;
    font-weight: 700;
    color: #655143
}

.payn-response-time {
    text-align: center;
    font-size: 22px;
    color: #fff;
    font-family: MuseoSans700;
    background: #f3a01a
}

.payn-response-time.visible-xs {
    color: #fff;
    background: #f3a01a
}

.hr-sla {
    padding: 0 0 0 197px;
    font-size: 26px;
    margin: -50px 0 0 0;
    color: #655143
}

@media only screen and (max-width:1299px) {
    .payn-header-tekst {
        margin: 102px -100px 0 100px
    }
}

@media only screen and (max-width:1199px) {
    .fixed-price-table-icon img {
        position: relative;
        top: 14px
    }

    .response-fx-time {
        font-size: 34px;
        font-weight: 700
    }

    .price-fx {
        font-size: 27px;
        font-weight: 700
    }

    .payn-header-tekst {
        margin: 102px -100px 0 0
    }

    .ers-head {
        padding: 0
    }

    li.baros {
        margin: 10px -300px 0 -10px
    }
}

.address-details {
    padding-left: 8px !important
}

@media only screen and (max-width:1000px) {
    .landingpage-header-tekst {
        margin: 100px 10px 0
    }

    .ers-head {
        padding: 0 10px
    }

    .baros-opsomming-header {
        margin: 0 0 0 10px
    }

    ul.baros-ul {
        padding: 15px 0 60px 45px
    }

    form.wpcf7-form {
        padding: 0 15px
    }

    .suaarp {
        padding: 0 0 0 15px
    }
}

@media only screen and (max-width:992px) {
    .payn-fx {
        padding: 0 0 0 99px;
        margin: -15px 0 -30px 0;
        font-size: 17px;
        color: #655143
    }
}

@media only screen and (max-width:795px) {
    .response-fx-time {
        font-size: 34px;
        font-weight: 700
    }

    .price-fx {
        font-size: 27px;
        font-weight: 700
    }

    .payn-fx {
        padding: 0 0 0 70px;
        margin: -15px 0 -30px 0;
        font-size: 17px
    }
}

@media (max-width:992px) {
    .container {
        width: initial;
        padding-left: 1em;
        padding-right: 1em
    }

    .resp-icon {
        margin: -40px 0 0 50px
    }

    .hr-sla {
        padding: 0 0 0 160px;
        font-size: 26px;
        margin: -50px 0 0 0
    }

    .hour-sla {
        margin: 0 0 0 120px;
        font-size: 49px;
        font-weight: 700
    }

    .fx-title-table {
        font-size: 16px;
        font-family: MuseoSans900;
        padding: 32px 0 0
    }

    .fx-subtitle-table {
        font-size: 17px
    }

    .payn-fixed-title {
        text-align: center;
        font-size: 22px;
        color: #655143;
        font-family: MuseoSans700;
        color: #fff;
        background: #f3a01a
    }
}

.sla-table-right {
    border-right: 1px solid #c1c9c8
}

.sla-table-left {
    border-left: 1px solid #c1c9c8
}

.sla-table-top {
    border-top: 1px solid #c1c9c8
}

.fx-priz {
    padding-top: 25px
}

@media (max-width:900px) {
    .sla-table-left:first-child {
        border-left: 0
    }

    .fx-priz {
        padding-top: 0
    }
}

.my_planHeader {
    text-align: center;
    color: #fff;
    padding-top: .2em;
    padding-bottom: .2em;
    color: #0193d7
}

.my_planTitle {
    font-size: 3em;
    font-weight: 700;
    color: #0193d7;
    padding: 10px 61px 15px 0;
    margin: 0
}

.my_planPrice {
    font-size: 1.4em;
    font-weight: 700;
    padding: 0 0 0 37px;
    margin: -75px 0 0 0
}

.my_planDuration {
    margin: -10px 0 20px 37px;
    color: #0193d7
}

@media (max-width:768px) {
    .pricing-dedicated-servers-balk {
        height: 70px
    }

    .my_feature {
        text-align: center;
        background-color: #E8F0EF
    }

    .resp-icon {
        margin: -40px 0 0 -30px
    }

    .hour-sla {
        margin: 0 0 0 65px;
        font-size: 49px;
        font-weight: 700
    }

    .hr-sla {
        padding: 0 0 0 140px;
        font-size: 26px;
        margin: -50px 0 0 0
    }

    .response-fx-time {
        font-size: 34px;
        font-weight: 700
    }

    .price-fx {
        font-size: 27px;
        font-weight: 700
    }

    .stroke-landingpage {
        margin: -130px 0 0 0
    }

    .landingpage-header-tekst {
        margin: -20px 10px 0 10px
    }
}

.my_feature {
    line-height: 2.5em;
    padding-left: 0;
    padding-right: 0
}

@media (max-width:767px) {
    .table-pricing-dedicated {
        margin: 0
    }

    .usp-landingpage-icons {
        padding: 30px 0 0
    }

    .response-time {
        text-align: center;
        font-size: 19px;
        font-family: MuseoSans700;
        color: #655143
    }

    .my_feature {
        text-align: center;
        background-color: #f8b133;
        border-top: 1px solid #c1c9c8;
        border-bottom: 1px solid #c1c9c8
    }

    .payn-opsomming-left {
        margin: 0;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0
    }

    .payn-opsomming-right {
        margin: 0;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0
    }
}

@media only screen and (max-width:633px) {
    .fx-subtitle-table {
        font-size: 16px
    }

    .payn-opsomming-left {
        margin-left: -80px
    }

    .payn-opsomming-right {
        margin-left: -80px
    }

    ul.baros-ul {
        padding: 15px 310px 60px 45px
    }
}

@media only screen and (max-width:502px) {
    div.price {
        text-align: center;
        font-size: 20px;
        font-weight: bolder
    }

    .sod-payn {
        margin: 0 -60px 0 -40px
    }
}

@media only screen and (max-width:480px) {
    .payn-opsomming-left {
        margin-left: -90px
    }

    .payn-opsomming-right {
        margin-left: -90px
    }

    .payn-support-tekst {
        font-size: 17px
    }

    .rc-st {
        font-size: 32px
    }
}

@media only screen and (max-width:420px) {
    span.price {
        font-family: MuseoSans700;
        font-size: 25px
    }

    .fx-title-table {
        font-size: 15px;
        font-family: MuseoSans900;
        padding: 10px 0 0
    }
}

.my_featureRow {
    margin-top: 1px;
    margin-bottom: 1px;
    border-top: 1px solid #c1c9c8
}

span.price {}

@media (max-width:375px) {
    span.price {
        font-size: 19px
    }
}

.table-vps-header {
    background: url(assets/images/vps-table-yellow.png) top center;
    min-height: 164px;
    position: relative;
    margin-top: 19px
}

.vps-best .table-vps-header {
    margin-top: 0;
    background: url(assets/images/vps-table-orange.png) top center;
    min-height: 183px;
    background-size: 100%
}

.vps-price {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    font-family: museosans900;
    font-size: 40px;
    color: #655143;
    margin-bottom: 5px
}

.vps-best .vps-price {
    color: #fff;
    font-size: 50px
}

.vps-price p {
    font-family: museosans700;
    font-size: 15px
}

.vps-title {
    background: #E8F0EF;
    text-align: center;
    padding: 5px 0
}

.vps-points {
    background: #ffeda1;
    padding: 25px 20px 40px
}

.vps-points ul {
    margin-bottom: 0
}

.vps-points ul li {
    text-indent: -47px;
    padding-left: 43px;
    font-size: 16px
}

.vps-points ul li:before {
    content: "";
    background: url(assets/images/vps-table-icon1.png);
    display: inline-block;
    margin-right: 9px;
    position: relative;
    height: 37px;
    width: 37px;
    top: 13px
}

.vps-points ul li:nth-child(2):before {
    background: url(assets/images/vps-table-icon2.png)
}

.vps-points ul li:nth-child(3):before {
    background: url(assets/images/vps-table-icon3.png)
}

.vps-points ul li:nth-child(4):before {
    background: url(assets/images/vps-table-icon4.png)
}

.vps-points ul li:nth-child(5):before {
    background: url(assets/images/vps-table-icon5.png)
}

.vps-best .vps-points {
    background: #f3a01a
}

.vps-best .vps-points li {
    color: #fff
}

.vps-button {
    background: #E8F0EF;
    text-align: center;
    padding: 18px 0
}

.vps-best .vps-button {
    padding: 15px 0
}

.vps-button .button {
    background: #00CE3C
}

.row-sky {
    position: relative
}

.row-sky:before {
    content: "";
    background: url(assets/images/clouds-bottom.png) bottom;
    width: 100%;
    top: 0;
    position: absolute;
    height: 206px;
    z-index: 1
}

.row-hills {
    position: relative
}

.row-hills .fl-row-content-wrap {
    min-height: 600px
}

.row-hills .txt-white {}

@media (min-width:1200px) {
    .row-hills .txt-white {
        margin-top: 260px
    }
}

.row-hills:after {
    content: "";
    background: url(assets/images/vps-bushes.png) top;
    width: 100%;
    bottom: 0;
    position: absolute;
    height: 206px;
    z-index: 1
}

.row-grass {
    position: relative
}

.row-grass:after {
    content: "";
    background: url(assets/images/vps-grass.png) top;
    width: 100%;
    bottom: 0;
    position: absolute;
    height: 100px;
    z-index: 1
}

.vps-star {
    position: relative;
    top: 20px
}

@-moz-document url-prefix() {
    .banner h1 {
        font-family: MuseoSans500
    }

    #___gcse_0 {
        min-width: 500px;
        float: right
    }

    @media (max-width:960px) {
        #___gcse_0 {
            min-width: 233px;
            float: left
        }
    }

    @media (max-width:800px) {
        #___gcse_0 {
            min-width: 180px;
            float: left
        }
    }

    @media (max-width:667px) {
        #___gcse_0 {
            float: left;
            min-width: 85%
        }
    }

    @media only screen and (max-width:420px) {
        span.price {
            font-family: MuseoSans700;
            font-size: 24px
        }

        .price-button-fx .price,
        .price-button-fx {
            font-family: museosans500 !important
        }
    }
}

:lang(nl-NL) #ttshowcase_form fieldset:nth-child(1)>label {
    font-size: 0
}

:lang(nl-NL) #ttshowcase_form fieldset:nth-child(1)>label:after {
    content: "Naam";
    display: block;
    font-size: 18px
}

:lang(nl-NL) #ttshowcase_form fieldset:nth-child(2)>label {
    font-size: 0
}

:lang(nl-NL) #ttshowcase_form fieldset:nth-child(2)>label:after {
    content: "Samenvatting";
    display: block;
    font-size: 18px
}

:lang(nl-NL) #ttshowcase_form fieldset:nth-child(5)>label {
    font-size: 0
}

:lang(nl-NL) #ttshowcase_form fieldset:nth-child(5)>label:after {
    content: "E-mail adres";
    display: block;
    font-size: 18px
}

:lang(nl-NL) #ttshowcase_form button.tt_form_button {
    font-size: 0
}

:lang(nl-NL) #ttshowcase_form button.tt_form_button:after {
    content: "Verzenden";
    display: block;
    font-size: 18px
}

@supports (-ms-accelerator:true) {
    .site-inner .fl-photo-content img {
        width: 100% !important;
        display: inline-block
    }

    .wpcf7 input[type=submit] {
        margin-top: 10px
    }
}

.page-pricing .responsive-table .unmetered {
    text-align: center
}


@media (min-width: 961px) {
    .mini-header {
        border-bottom: none;
    }

    .mini-header:before {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        content: ' ';
        z-index: 0;
    }

    #menu-menu.genesis-nav-menu>.menu-item:hover>a,
    #menu-menu.genesis-nav-menu>.menu-item:hover>.sub-menu,    
    #menu-hoofdmenu.genesis-nav-menu>.menu-item:hover>a,
    #menu-hoofdmenu.genesis-nav-menu>.menu-item:hover>.sub-menu {
        z-index: 155;
    }
    #menu-menu-1.genesis-nav-menu>.menu-item:hover>a,
    #menu-menu-1.genesis-nav-menu>.menu-item:hover>.sub-menu,
    #menu-hoofdmenu-1.genesis-nav-menu>.menu-item:hover>a,
    #menu-hoofdmenu-1.genesis-nav-menu>.menu-item:hover>.sub-menu {
        z-index: 1005;
    }

    #menu-menu-1.genesis-nav-menu>.menu-item:hover>a,
    #menu-menu.genesis-nav-menu>.menu-item:hover>a,
    #menu-hoofdmenu-1.genesis-nav-menu>.menu-item:hover>a,
    #menu-hoofdmenu.genesis-nav-menu>.menu-item:hover>a {
        position: relative;
        background-color: #ffffff;
    }

    nav>.wrap:hover {
        position: static;
    }

    #menu-menu,
    #menu-hoofdmenu {
        position: relative;
    }

    #menu-menu:hover,
    #menu-hoofdmenu:hover {
        z-index: 151;
    }

    body .menu-focus-bg {
        width: 0;
        height: 0;
        line-height: 0;
        opacity: 0;
        -webkit-transition: opacity .4s ease-in-out;
        transition: opacity .4s ease-in-out;
    }

    body .menu-focus-bg.active {
        opacity: 1;
        position: fixed;
        z-index: 150;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .mini-header.menu-active>.wrap {
        z-index: 2;
        position: relative;
    }
    .mini-header.menu-active:before {
		display: block;		
    }
    
    .mini-header.menu-active>.wrap>.alignleft img{
        opacity: 0.4;
    }
}

@media (min-width: 1100px) {
    #menu-menu,
    #menu-hoofdmenu {
        float: right;
        width: auto;
    }
}


div.wpforms-container-full.snel-form .wpforms-form input[type=date],
div.wpforms-container-full.snel-form .wpforms-form input[type=datetime],
div.wpforms-container-full.snel-form .wpforms-form input[type=datetime-local],
div.wpforms-container-full.snel-form .wpforms-form input[type=email],
div.wpforms-container-full.snel-form .wpforms-form input[type=month],
div.wpforms-container-full.snel-form .wpforms-form input[type=number],
div.wpforms-container-full.snel-form .wpforms-form input[type=password],
div.wpforms-container-full.snel-form .wpforms-form input[type=range],
div.wpforms-container-full.snel-form .wpforms-form input[type=search],
div.wpforms-container-full.snel-form .wpforms-form input[type=tel],
div.wpforms-container-full.snel-form .wpforms-form input[type=text],
div.wpforms-container-full.snel-form .wpforms-form input[type=time],
div.wpforms-container-full.snel-form .wpforms-form input[type=url],
div.wpforms-container-full.snel-form .wpforms-form input[type=week],
div.wpforms-container-full.snel-form .wpforms-form select,
div.wpforms-container-full.snel-form .wpforms-form textarea {
    border-radius: 14px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    padding: 13px;
    font-size: 1em;
    min-height: 56px;
    background-color: #fff;
    border: 1px solid #ddd;
    color: #333;
    font-weight: 300;
}

div.wpforms-container-full.snel-form .wpforms-form select {
    -webkit-appearance: none;
}

div.wpforms-container-full.snel-form .wpforms-form button[type=submit] {
    padding: 12px 62px;
    background-color: #0391d6;
    border-radius: 14px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    font-size: 1em;
    min-height: 56px;
    border: 0;
    color: #fff;
    cursor: pointer;
    width: auto;
}

div.wpforms-container-full.snel-form .wpforms-form button[type=submit]:hover {
    border: none;
    background: #0380bd;
}

div.wpforms-container-full.snel-form .wpforms-form .wpforms-field-label {
    margin-bottom: 0;
    margin-top: 0;
    line-height: 2.4;
    letter-spacing: .25px;
    font-family: museosans500;
    font-size: 18px;
    color: #333;
    font-weight: 300;
}

div.wpforms-container-full.snel-form .wpforms-form .wpforms-field ul li:before {
    display: none;
    content: '';
}

@media (max-width: 480px) {
    div.wpforms-container-full.snel-form .wpforms-form .wpforms-field.wpforms-one-half {
        width: 100%;
        float: none;
        margin: 0;
    }
}

@media (max-width: 800px) {
    div.wpforms-container-full.snel-form .wpforms-form .wpforms-submit-container button[type=submit] {
        width: 100%;
    }
}

.support-portal-popup,
.main-popup {
    display: none;
    position: fixed;
    border: 0px;
    overflow: hidden;
    width: 450px;
    height: auto;
    max-height: 400px;
    z-index: 999997;
    top: 50%;
    left: 50%;
    margin-left: -225px;
    margin-top: -200px;
    box-shadow: 0px 0px 10px 2px #00000061;
}
.support-portal-popup.shown,
.main-popup.shown {
    display: block;
}

.spp-close-button-block {
    position: absolute;
    z-index: 9999999;
    width: auto;
    padding-top: 5px;
    padding-left: 5px;
}

.spp-popup-close {
    background: transparent;
    border: 2px solid #f19519;
    border-radius: 50%;
    padding: 5px 10px;
    top: 5px;
    font-size: 16px;
    outline: none;
    color: #f19519;
}

.main-popup .spp-close-button-block {
    right: 5px;
}

.main-popup .spp-popup-close {
    color: #000000;
    border: 2px solid #000000;
}

@media (max-width: 470px) {
    .support-portal-popup,
    .main-popup {
       width: 300px;
       height: auto;
       max-height: 266px;
       margin-left: -150px;
       margin-top: -133px;
    }
}

@media (max-width: 320px) {
    .support-portal-popup,
    .main-popup {
        width: 250px;
        height: auto;
        max-height: 222px;
        margin-left: -125px;
        margin-top: -111px;
    }
}

.section-full-loading {
    width: 100%;
    margin: 0;
}

.section-full-loading>.fl-row-content-wrap {
    padding: 0;
    margin: 0;
    position: relative;
}

.section-full-loading>.fl-row-content-wrap>.fl-row-content>.fl-col-group>.fl-col {
    width: 100%;
    float: none;
}

@media (min-width: 801px) {
    .section-full-loading,
    .section-full-loading>.fl-row-content-wrap>.fl-row-content>.fl-col-group>.fl-col {        
        width: 100%;
        margin: 0;
    }
}

@media (min-width: 1024px) {
    .section-full-loading,
    .section-full-loading>.fl-row-content-wrap>.fl-row-content>.fl-col-group>.fl-col {        
        width: 100%;
        margin: 0;
    }
}

@media (min-width: 1200px) {
    .section-full-loading,
    .section-full-loading>.fl-row-content-wrap>.fl-row-content>.fl-col-group>.fl-col {        
        width: 100%;
        margin: 0;
    }
}

/* Year End Sales Tables */
.ye-sale-table {
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #e8f0ef;
    background-color: #ffffff;
}

.yest-table-title {
    background-color: #dbeaf1;
    padding: 20px 10px;
}

.yest-table-title h3 {
    margin-bottom: 0;
    text-align: center;
}

.yestsi-title {
    padding: 40px 30px 0;
}

.yestsi-title h4 {
    text-align: center;
    color: #272e2a;
    margin-bottom: 30px;
    font-family: museosans900;
    font-size: 18px;
}

.yestsi-info {
    color: #6f6f70;
    font-size: 18px;
    font-family: museosans500;
    text-align: center;
    padding: 10px 30px 0;
}

.yestsi-info-item {
    width: 100%;
    line-height: 20px;
    border-top: 1px solid #e8f0ef;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
}

.yestsi-info-item:first-child {
    border-top: none;
}

.yestsi-info-item-title {
    max-width: 100%;
    text-align: left;
    font-size: 14px;
    flex-shrink: 0;
    width: 40%;
}

.yestsi-info-item-info {
    max-width: 100%;
    text-align: right;
    font-size: 14px;
    line-height: 14px;
    width: 60%;
}

.yestsi-info-item-info-up {
    text-align: right;
    font-size: 12px;
    color: #68808e;
    font-family: museosans300;
}

.yestsi-info-delivery {
    padding: 10px;
    text-align: center;
    font-size: 16px;
    color: #019952;
    font-family: museosans900;
    text-transform: uppercase;
}

.yestsi-info-delivery-title {
    font-size:16px;
    color:#595c5b;
    font-family: museosans900;
}

.yestsi-info-delivery-value {
    font-size: 14px;
    color: #878787;
    font-family: museosans500;
    line-height: 14px;
    text-transform: initial;
}

.yestsi-pricing {
    padding: 15px 10px 0;
}

.yestsi-pricing-price-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.yestsi-pricing-value {
    padding-right: 5px;
    text-align: center;
}

.yestsi-pricing-value-new {
    font-size: 36px;
    line-height: 36px;
    color: #272e2a;
    font-family: museosans700;
    white-space: nowrap;
}

.yestsi-pricing-value-old {
    font-size: 18px;
    color: #878787;
    font-family: museosans500;
    line-height: 18px;
    text-decoration: line-through;
}

.yestsi-pricing-value-period {
    font-size: 14px;
    color: #878787;
    font-family: museosans500;
    line-height: 14px;
}

.yestsi-pricing-block-saving {
    padding-left: 5px;
}

.yestsi-pricing-block-saving-circle {
    text-align: center;
    background-color: #319951;
    border-radius: 50%;
    padding: 18px;
    -moz-transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
}

.yestsi-pricing-save-value {
    font-size: 18px;
    color: #ffffff;
    font-family: museosans500;
    line-height: 18px;
    white-space: nowrap;
}

.yestsi-pricing-save-text {
    font-size: 20px;
    color: #ffffff;
    font-family: museosans500;
    line-height: 20px;
}

.yestsi-pricing-price-period {
    padding: 15px 0;
    font-size: 16px;
    color: #6f6f70;
    font-family: museosans500;
    line-height: 16px;
    text-align: center;
}

.yestsi-button-block {
    padding: 25px 15px;
}

.yestsi-button-block-btn {
    width: 100%;
    border-radius: 0;
    -webkit-transition: background-color 0.3s ease;
    -moz-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
    background-color: #f3a019;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    font-family: museosans700;
}

.yestsi-button-block-btn:hover {
    background-color: #dc8800;
}

.yestsi-coupon-block {
    background-color: #f19519;
    padding: 10px 15px;
}

.yestsi-coupon-text {
    text-align: center;
    color: #ffffff;
    padding: 0 0 10px;
    font-size: 16px;
    line-height: 18px;
}

.yestsi-coupon-value {
    text-align: center;
}

.yestsi-coupon-value span {
    color: #ffffff;
    font-size: 20px;
    line-height: 20px;
    font-family: museosans900;
    letter-spacing: 1px;
}

.copy-to-clipboard-el {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 14px;
    padding: 3px 5px;
    border-radius: 5px;
}

.yes-banner-click {
    cursor: pointer;
}

@media (min-width: 961px) {
    .nav-primary .genesis-nav-menu .sub-menu a,
    .mini-header .sub-menu a {
        white-space: nowrap;
        width: 275px;
    }    
}

/*Snel Bottom Menu*/
@media (max-width: 960px) {
    #snel-mobile-bottom-menu {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
    }

    .snel-mobile-bottom-menu_inner {
        width: 100%;
        display: flex;
        flex-direction: row
    }

    .snel-mobile-bottom-menu-item {
        width: 20%;    
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
    }

    .snel-mobile-bottom-menu-item > a,
    .snel-mobile-bottom-menu-item>button {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 5px;
        justify-content: flex-end;
        align-items: center;
        background-color: #1992D4;
        width: 100%;
        min-height: 50px; 
        color: #e8f0ef;
        height: 50px;
        transition: background-color 0.1s ease-in-out,
        color 0.1s ease-in-out;
    }

    .snel-mobile-bottom-menu-item>a:focus,
    .snel-mobile-bottom-menu-item>button:focus,
    .snel-mobile-bottom-menu-item>a:hover,
    .snel-mobile-bottom-menu-item>button:hover {
        color: #e8f0ef;
        background-color: #1992D4;
        outline: none;
    }

    .snel-mobile-bottom-menu-item>a.active,
    .snel-mobile-bottom-menu-item>button.active {
        color: #ffffff;
        background-color: #F69231;
    }

    .snel-mobile-bottom-menu-item>a>i,
    .snel-mobile-bottom-menu-item>button>i {
        font-size: 22px;
    }

    .snel-mobile-bottom-menu-item>a>span,
    .snel-mobile-bottom-menu-item>button>span {
        font-size: 11px;
    }

    #smbm-lg-switcher>.widget-area {
        width: 100%;
        height: 50px;
        text-align: center;
        vertical-align: middle;
        background-color: #1992D4;
        color: #e8f0ef;
    }

    #smbm-lg-switcher>.widget-area>section {
        width: 100%;
        height: 100%;
    }

    #smbm-lg-switcher>.widget-area .widget .current-lang {
        max-height: 100%;
        min-height: 50px;
        background-color: #1992D4;
    }

    #smbm-lg-switcher>.widget-area .widget .current-lang i,
    #smbm-lg-switcher>.widget-area .widget .current-lang .current-lang-code,
    #smbm-lg-switcher>.widget-area .widget .lang-switcher-dropdown .lang-list .top-switcher-item>a .top-switcher-item-code {
        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* IE10+/Edge */
        user-select: none;
        /* Standard */
        color: #e8f0ef;
    }

    #smbm-lg-switcher>.widget-area .widget .lang-switcher-dropdown {
        height: 50px;
        width: 100%;
        top: -100%;
        background-color: #1992D4;
    }

    #smbm-lg-switcher>.widget-area .widget .lang-switcher-dropdown .lang-list .top-switcher-item > a{
        text-align: center;
        min-height: 50px;
    }

    #smbm-lg-switcher>.widget-area .widget .top-switcher .lang-list .top-switcher-item.current-lang,
    #smbm-lg-switcher>.widget-area .widget .top-switcher .top-switcher-item .top-switcher-item-name,
    #smbm-lg-switcher>.widget-area .widget .top-switcher .top-switcher-item .top-switcher-item-flag {
        display: none;
    }

    #smbm-lg-switcher>.widget-area .widget .top-switcher .top-switcher-item {
        background-color: #1992D4;
    }

    #smbm-lg-switcher>.widget-area .widget .top-switcher .top-switcher-item .top-switcher-item-code {
        display: initial;
    }

    body.fl-builder-mobile .livechat > div > div {
        right: 5px!important;
        bottom: 100px!important;
    }

    #snel-mobile-bottom-menu_search-button > button,
    .snel-mobile-bottom-menu-item:nth-child(4) > a {
        border-right: 1px solid #e8f0ef;
        border-left: 1px solid #e8f0ef;
    }

    #snel-mobile-bottom-menu_search-button .smbm-search-wrap {
        display: none;
        position: absolute;
        top: -46px;
        width: 100%;
        right: 0;
        height: 46px;
    }

    #snel-mobile-bottom-menu_search-button .smbm-search-wrap.open {
        display: block;
    }

    #snel-mobile-bottom-menu_search-button .smbm-search-wrap form {
        background: #F69231;
        padding: 4px 0px 5px;
        vertical-align: bottom;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 46px;
        max-width: 100%;
    }

    #snel-mobile-bottom-menu_search-button .search-form_new input[type="submit"] {
        background-color: #F69231;
        border-radius: 0;
        padding: 7.5px 22px;
        position: relative;
        top: 2.5px;
        left: -2px;
        background-image: url(assets/images/search_box_icon.png);
        background-size: 17px 24px;
        background-repeat: no-repeat;
        background-position: center center;
    }

    #snel-mobile-bottom-menu_search-button .search-form_new input[type="search"] {
        padding: 8px;
        box-sizing: border-box;
        border-radius: 7px;
        margin: 0;
        border: 1px solid #eee;
        background: #fff;
        font-weight: normal;
        font-family: 'museosans500';
        width: 84%;
        vertical-align: top;
        margin-top: 1px;
        color: #333;
        font-size: 1.8rem;
        margin-left: 5px;
    }

    #snel-mobile-bottom-menu_menu-button {
        height: 55px;
    }

    #snel-mobile-bottom-menu_menu-button:not(.active) i.fa-times,
    #snel-mobile-bottom-menu_menu-button.active i.fa-bars,
    #snel-mobile-bottom-menu_search-button>button:not(.active) i.fa-times,
    #snel-mobile-bottom-menu_search-button>button.active i.fa-search {
        display: none;
    }

    body.mkb-mobile #ui-id-1.ui-widget-content.ui-autocomplete,
    body.mkb-mobile #ui-id-2.ui-widget-content.ui-autocomplete {
        bottom: 96px!important;
        top: auto!important;
        left: 0!important;
        position: fixed!important;
        height: auto!important;
        max-height: 75%!important;
        overflow-y: scroll!important;
        width: 100%!important;       
    }

    body.mkb-mobile #ui-id-1.ui-widget-content.ui-autocomplete .ui-menu-item,
    body.mkb-mobile #ui-id-2.ui-widget-content.ui-autocomplete .ui-menu-item {
         color: #f3a01a;
        padding: 5px 0;
        border-bottom: 2px solid #ebebeb;
    }

    body.mkb-mobile .utility-bar {
        display: none;
    }

    body.mkb-mobile .mini-header {
        display: none!important;
    }

    header.site-header.mobile-menu-open {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 50px;
        height: auto;
        width: 100%;
        z-index: 99;
        overflow-y: scroll;
    }

    header.site-header.mobile-menu-open.sbm-search-open {
        margin-bottom: 46px;
    }

    header.site-header.mobile-menu-open button.menu-toggle {
        visibility: hidden;
    }

    body.mkb-mobile .site-header {
        min-height: 70px;
    }

    body.mkb-mobile .site-header .wrap {
        height: 70px;
        min-height: 70px;
    }

    body.mkb-mobile .site-header .title-area,
    body.mkb-mobile .site-header .site-title {
        height: 70px;
    }

    body.mkb-mobile .site-header .title-area {
        width: 100%;
        text-align: center;
    }

    body.mkb-mobile .site-header .site-title {
        display: inline-block;
        text-indent: 0;
    }

    body.mkb-mobile .site-header .site-title>a {
        color: transparent;
    }

    body.mkb-mobile .header-widget-area button.menu-toggle {
        padding: 0 10px !important;
        visibility: hidden;
    }
    
    body.mkb-mobile .fl-snel-price-banner .fl-snel-price-banner-plans-conteiner {
        padding-top: 0;
    }

    body.mkb-mobile .fl-snel-price-banner .fl-snel-price-banner-hero-description p {
        margin-bottom: 0;
    }

    body.mkb-mobile #cookie-notice {
        bottom: 50px;
    }

    body.mkb-mobile.sbm-search-open #cookie-notice {
        bottom: 101px;
    }
}

@media (min-width: 961px) {
    #snel-mobile-bottom-menu {
        display: none;
    }
}

body.mkb-desktop #snel-mobile-bottom-menu {
    display: none;
}

@media (max-width: 831px) {
    .responsive-table tbody td[data-title]:before {
        font-size: 1em;
    }
}

@media (max-width: 800px) {
    .img-hide-on-mobile {
        display: none;
    }
}

.img-max-width-75 img{
    max-width: 75%!important;
}

.row-block-round-grey>.fl-row-content-wrap>.fl-row-content>.fl-col-group {
    background-color: #e8f0ef;
    border-radius: 15px;
}

.footer-bottom-iso {
    display: none;
}

@media (max-width: 1050px) {
    .footer-top-iso {
        display: none;
    }

    .footer-bottom-iso {
        display: initial;
    }
}

